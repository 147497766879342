import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query'
import axios from 'axios';
import styles from './ForgotPassword.module.css';
import BaseCard from "../ui/BaseCard";
import BaseButton from "../ui/BaseButton";
import PasswordField from "../ui/PasswordField";
import validator from "validator";
import AccountLayout from '../Layouts/AccountLayout';
import Alert from '../ui/Alert';

export default function ResetPassword(props) {
  const router = useRouter();
  const { token } = router.query;
  const [form, setForm] = useState({ password: '', repeatPassword: '' });
  const [formErrors, setFormErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });

    if (!!formErrors[field]) {
      setFormErrors({
        ...formErrors,
        [field]: ""
      });
    }
  };

  const validateForm = () => {
    const password = form.password || "";
    const repeatPassword = form.repeatPassword || "";

    const errors = {};

    if (validator.isEmpty(password))
      errors.password = "Please enter a password";
    else if (!validator.isLength(password, { min: 8 }))
      errors.password = "Please use at least 8 characters";

    if (validator.isEmpty(repeatPassword))
      errors.repeatPassword = "Please repeat the password";
    else if (password !== repeatPassword)
      errors.repeatPassword = "Does not match password";

    return errors;
  };

  const mutation = useMutation((event) => {
    event.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      throw new Error("This form has errors. Please fix them before proceeding.");
    }

    return axios.post(`${process.env.NEXT_PUBLIC_API_URL}/reset/${token}/`, { password: form.password });
  }, {
    onError: (error, variables, context) => {
      console.log(error)
    },
    onSuccess: (data, variables, context) => {
      setTimeout(() => router.replace('/login'), 10000);
    },
  })

  function displayMessage() {
    if (mutation.isError) {
      return (
        <div className={styles.hud}>
          <Alert variant='error'>{mutation.error.message}</Alert>
        </div>
      );
    }
    else if (mutation.isSuccess) {
      return (
        <div className={styles.hud}>
          <Alert variant='success'>
            Password successfully reset.
            <br />
            <br />
            Redirecting back to the login page in 10 seconds...
          </Alert>
        </div>
      );
    }
  }

  return (
    <AccountLayout>
      <BaseCard title="Reset Password" className={styles.card}>
        {displayMessage()}
        <div className={styles.instruction}>Enter your new password and then repeat it.</div>
        <form role="form" onSubmit={mutation.mutate}>
          <div className="mb-10">
            <label className="block text-gray-500  mb-3" htmlFor="password">
              New Password
            </label>
            <PasswordField id="new-password" placeholder="Enter new password"
              value={form.password}
              onChange={(e) => { setField('password', e.target.value) }} />
              {formErrors.password &&
                <p className="error">{formErrors.password}</p>
              }
          </div>
          <div className="mb-10">
            <label className="block text-gray-500 mb-3" htmlFor="password">
              Repeat Password
            </label>
            <PasswordField id="password" placeholder="Enter new password again"
              autoComplete="new-password"
              value={form.repeatPassword}
              onChange={(e) => { setField('repeatPassword', e.target.value) }} />
              {formErrors.repeatPassword &&
                <p className="error">{formErrors.repeatPassword}</p>
              }
          </div>
          <BaseButton
            width="100"
            type="submit"
            disabled={mutation.isLoading}
          >
            Reset Password
          </BaseButton>
        </form>
        <div className={`${styles['actions__row1']} text-md`}>
          <div>
            <button onClick={() => router.push('/login')} className={styles.link}>
              <img src="/arrow-left.svg" className={styles.link__arrow} />
              <span className={styles.link__text}>Back to Login</span>
            </button>
          </div>
        </div>
      </BaseCard>
    </AccountLayout>
  );
};
