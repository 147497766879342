import { useState, useEffect} from "react";
import { getSessionStorage, setSessionStorage } from "./persistentStorage";

const useSessionStorage = (key, defaultValue) => {
  const [state, setState] = useState(() => defaultValue);
  const [readyToRender, setReadyToRender] = useState(false);

  // Init localStorage (avoid rendering before first read from localStorage)
  useEffect(() => {
    setState(getSessionStorage(key, defaultValue));
    setReadyToRender(true);
  }, [key, defaultValue]);

  // Update localStorage whenever react state changes
  useEffect(() => {
    setSessionStorage(key, state);
  }, [key, state]);

  return [state, setState, readyToRender];
}

export default useSessionStorage;
