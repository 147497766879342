export default function BaseButton({ type, disabled, loadingText, children, className, dataCy, onClick = () => {} }) {
	return (
		<button
			type={type}
			disabled={disabled}
			className={`font-bold w-full rounded-lg text-lg text-white p-4 bg-purple hover:bg-purple-light disabled:bg-purple-light disabled:cursor-not-allowed ${className}`}
			onClick={onClick}
      data-cy={dataCy}
		>
			{disabled ?
				<div className="flex align-center justify-center gap-1">
					<span>{loadingText || 'Processing...'}</span>
				</div>
				:
				<>
					{children}
				</>
			}
		</button>
	);
}
