import { AuthProvider } from '../ztn/contexts/Auth'
import { DarkThemeProvider } from '../ztn/contexts/Theme'
import RootComponent from '../RootComponent'
import { UserStateProvider } from '../ztn/contexts/User'
import { AwsProvider } from '../ztn/contexts/Aws';

const AuthLayout = (props) => {
	return (
      <DarkThemeProvider>
			  <UserStateProvider>
      			<AuthProvider>
              <AwsProvider>
                <RootComponent {...props} />
              </AwsProvider>
	  			</AuthProvider>
			  </UserStateProvider>
		  </DarkThemeProvider>
	)

}

export default AuthLayout;
