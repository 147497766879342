
export default function BaseCard(props) {
	return (
		<div className="mt-10 mx-auto max-w-lg p-12 shadow-lg">
			<div className="max-w-sm mx-auto">
				{props.title &&
					<div className="text-4xl font-bold mb-8 text-purple-700">
						{props.title}
					</div>
				}
				{props.children}
			</div>
		</div>
	);
}
