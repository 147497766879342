import { useState, useEffect} from "react";
import { getLocalStorage, setLocalStorage } from "./persistentStorage";

const useLocalStorage = (key, defaultValue) => {
  const [state, setState] = useState(() => defaultValue);
  const [readyToRender, setReadyToRender] = useState(false);

  // Init localStorage (avoid rendering before first read from localStorage)
  useEffect(() => {
    setState(getLocalStorage(key, defaultValue));
    setReadyToRender(true);
  }, [key, defaultValue]);

  // Update localStorage whenever react state changes
  useEffect(() => {
    setLocalStorage(key, state);
  }, [key, state]);

  return [state, setState, readyToRender];
}

export default useLocalStorage;
