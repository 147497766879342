import React, { useEffect, useRef, useCallback } from 'react'
import DataviewerModal from './DataviewerModal';
import ReactDom from 'react-dom'


export default function Modal({ open, onClose, content, children, miniModal, title, type, filename }) {
    const modalRef = useRef()


    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && open) {
            onClose()
        }
    }, [open, onClose])

    function closeModal(e) {
        e.stopPropagation()
        if (modalRef.current) {
            if (modalRef.current !== e.target && !modalRef.current.contains(e.target)) {
                onClose()
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyPress)
        return () => document.removeEventListener('keydown', keyPress)
    }, [keyPress])

    useEffect(() => {
        document.addEventListener('mousedown', closeModal)
        return () => document.removeEventListener('mousedown', closeModal)
    }, [])

    if (!open) { return null }

    let body

    if (type === 'dataviewer') {
        body = (
            <DataviewerModal modalRef={modalRef} filename={filename} onClose={onClose} content={content} />
        )
    } else if (type === 'lightBox') {
        let chevronLeft = content.imgUrls > 1 ? (
            <>
                <div onClick={() => content.setCurrImgIndex(curr => curr - 1 < 1 ? 0 : curr - 1)} className='absolute top-1/2 left-0 translate-x-full text-xl z-40 cursor-pointer -translate-y-1/2'><i className="fa-solid fa-chevron-left text-white"></i></div>
            </>
        ) : null
        let chevronRight = content.imgUrls > 1 ? (
            <>
                <div onClick={() => content.setCurrImgIndex(curr => curr + 1 <= content.imgUrls.length - 1 ? curr + 1 : content.imgUrls.length - 1)} className='absolute z-40 top-1/2 right-0 -translate-x-full text-xl cursor-pointer -translate-y-1/2'><i className="fa-solid fa-chevron-right text-white"></i></div>

            </>
        ) : null

        body = (
            <div className='fixed top-0 left-0 w-screen h-screen z-40 flex flex-col gap-10'>
                <div onClick={onClose} className='absolute inset-0 bg-black inset-0 opacity-70 z-30'></div>
                <div className='flex items-center justify-center gap-4 p-4 bg-slate-700 relative'>
                    <div onClick={() => content.setCurrImgIndex(curr => curr - 1 < 1 ? 0 : curr - 1)} className=' text-xl z-40 cursor-pointer '><i className="fa-solid fa-chevron-left text-white"></i></div>
                    <div onClick={() => content.setCurrImgIndex(curr => curr + 1 <= content.imgUrls.length - 1 ? curr + 1 : content.imgUrls.length - 1)} className=' text-xl z-40 cursor-pointer'><i className="fa-solid fa-chevron-right text-white"></i></div>
                    <div onClick={onClose} className='absolute top-1/2 right-0 px-7 z-40 cursor-pointer  text-white text-lg -translate-y-1/2'>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className='flex flex-1 flex-col items-center justify-center max-h-[80vh] overflow-hidden'>

                    <img className=' max-w-[600px] max-h-[600px] relative z-50 flex-1' src={content.imgUrls[content.currImgIndex]} alt="image" />
                </div>
                <div className='flex overflow-scroll z-40 relative items-center justify-center gap-8'>
                    {content?.imgUrls.map((url, i) => {
                        return (
                            <div className={'border-2 border-solid duration-200 ' + (i === content.currImgIndex ? ' border-white' : ' border-transparent')} key={i} onClick={() => content.setCurrImgIndex(i)}>
                                <img className='object-cover cursor-pointer h-14 aspect-square' src={url} alt={i} />
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    } else {
        body = (
            <div className='fixed top-0 left-0 w-screen h-screen bottom-0 z-40 grid place-items-center ' data-cy="modal">
                <div className='absolute bg-black opacity-50 inset-0 z-30'></div>
                <div className='relative w-full flex flex-col max-w-full sm:w-10/12 h-content sm:h-4/6 overflow-y-auto bg-white shadow-lg border border-solid border-slate-200 p-4 z-50' ref={modalRef}>
                    <div className='relative z-100 justify-between flex '>
                        <h1 className='text-violet-700'>{!miniModal ? "" : title ? title : 'Recycling Bin'}</h1>
                        <button
                            className='cursor-pointer relative h-3 w-3 before:absolute before:inset-0 before:origin-center before:w-1 before:h-full before:bg-gradient-to-b from-purple-500 to-pink-500 before:rotate-45 after:absolute after:inset-0 after:origin-center after:w-1 after:h-full after:bg-gradient-to-b from-violet-700 to-red-700 after:-rotate-45 after:rounded before:rounded after:hover:transition-opacity after:hover:opacity-50 before:hover:transition-opacity before:hover:opacity-50'
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className='pt-2 flex-1 flex flex-col'>
                        {content ? content : null}
                        {children ? children : null}

                    </div>
                </div>
            </div>
        )
    }
    return ReactDom.createPortal(body, document.getElementById('portal')
    )
}
