import React, { useCallback, useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import { useUploadState } from './stores/uploader'

export default function ZustandUploader(props) {
    const [opened, setOpened] = useState(true)
    const [render, setRender] = useState([])
    const [clear, setClear] = useState(false)
    const removeUpload = useUploadState((state) => state.deleteFromQueue)
    const uploads = useUploadState(useCallback(state => state.uploadQueue, [render]))
    const dontShow = new Set(['Ready', 'Error'])

    useEffect(() => {
        setRender(Object.values(uploads).filter(upload => !dontShow.has(upload.upload_status.status)))
    }, [uploads])

    if (render.length == 0) {
        return null
    }

    return ReactDom.createPortal(
        //going to display the model piece
        <div className={'fixed z-50 w-full  top-0  h-screen flex items-end pointer-events-none transition duration-300 ' + (opened ? 'right-0' : 'right-8 translate-x-full')}>
            <div className=' border border-solid border-gray-100 bg-white shadow-lg w-full pointer-events-auto flex items-center max-h-72 overflow-auto'>
                <div className={'px-3 cursor-pointer transition duration-300 grid place-items-center bg-white hover:opacity-50 ' + (opened ? '' : '')}
                    onClick={() => setOpened(!opened)}>
                    {opened ?
                        <i className="fa-solid fa-chevron-right opacity-70"></i> :
                        <i className="fa-solid fa-chevron-left opacity-70"></i>}
                </div>
                <div className='flex-1 flex flex-col gap-2 overflow-y-auto max-h-full pr-4 select-none py-4'>
                    {render.map((upload, index) => {
                        return (<div key={index} className="flex py-2 px-4 rounded relative bg-purple-200 text-white">
                            <div className='flex-1 truncate relative z-10'>
                                {upload.name}
                            </div>
                            <div className='flex-1 z-10'>{upload.upload_status.status}</div>
                            <div style={{ width: `min(${upload.upload_status.statusValue}%, calc(100%))` }} className="h-full absolute top-0 rounded left-0 bg-gradient-to-r from-purple-500 to-rose-500">

                            </div>
                            { upload.upload_status.hasError ?
                                <span
                                    className="fa-solid fa-xmark opacity-100"
                                    style={{width: '25px', height: '25px', marginTop: '2px', fontSize: '1.25rem', cursor: 'pointer'}}
                                    onClick={() => removeUpload(upload.name)}></span> : null }
                        </div>)
                    })}
                </div>


            </div>
        </div>
        , document.getElementById('secondPortal'))
}
