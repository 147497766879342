import { QueryClientProvider, QueryClient } from 'react-query'

export const queryClient = new QueryClient()

const AsyncQueryProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  )
}

export default AsyncQueryProvider
