import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useMutation } from "react-query";
import axios from "axios";
import { useContext } from "react";
import BaseCard from "../ui/BaseCard";
import BaseButton from "../ui/BaseButton";
import PasswordField from "../ui/PasswordField";
import Auth, { getProtectorBaseDomain } from "./contexts/Auth";
import validator from "validator";
import Alert from "../ui/Alert";
import mixpanel from "../../mixpanel";
import Cookies from "js-cookie";

const errorDict = {
  401: "No matching credentials found.",
};

const Login = () => {
  const { toggleLogin } = useContext(Auth);
  const [remember, setRemember] = useState(true);
  const [form, setForm] = useState({ email: "", password: "" });
  const [formErrors, setFormErrors] = useState({});

  //const [text, setText] = useState("Register")

  const [loginState, setLoginState] = useState("Login")

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!formErrors[field]) {
      setFormErrors({
        ...formErrors,
        [field]: "",
      });
    }
  };

  useEffect(() => {
    mixpanel.track("View Login Page");
  }, []);

  const validateForm = () => {
    const email = form.email || "";
    const password = form.password || "";
    const errors = {};

    if (validator.isEmpty(email))
      errors.email = "Please enter an email address";
    else if (!validator.isEmail(email))
      errors.email = "Please enter a valid email address";

    if (validator.isEmpty(password))
      errors.password = "Please enter a password";

    return errors;
  };

  const mutation = useMutation(
    (event) => {
      event.preventDefault();

      const errors = validateForm();

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        throw new Error(
          "This form has errors. Please fix them before proceeding."
        );
      }

      return axios.post(`${process.env.NEXT_PUBLIC_API_URL}/login`, {
        email: form.email,
        password: form.password,
      });
    },
    {
      onError: (error, variables, context) => {
        // error state update
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        const { user_id, token } = data.data;
        mixpanel.identify(user_id);
        mixpanel.people.set();
        Cookies.set("zuser", form.email, {
          expires: 7,
          domain: getProtectorBaseDomain(),
        });
        toggleLogin(token);
      },
    }
  );

  return (
    <BaseCard title="Login">
      <Alert
        className="mb-4"
        show={mutation.isError && !mutation.isLoading}
        variant="error"
      >
        {formDecodedError(mutation.error)}
      </Alert>
      <form role="form" onSubmit={mutation.mutate}>
        <div className="mb-10">
        <div className="p-3 border-[1.5px] mb-4 border-solid border-emerald-400 bg-emerald-100 text-center" >
          Get $50 free credit when you sign up!
      </div>
          <label className="block text-gray-500  mb-3" htmlFor="username">
            Email
          </label>
          <input
            className="border border-solid border-gray-300 rounded w-full py-3 px-6 text-gray-700 bg-transparent focus:ring-0 focus:border-gray-300 "
            id="username"
            type="text"
            placeholder="Enter email address"
            value={form.email}
            onChange={(e) => {
              setField("email", e.target.value);
            }}
          />
          {formErrors.email && <p className="error">{formErrors.email}</p>}
        </div>
        <div className="mb-10">
          <label className="block text-gray-500 mb-3" htmlFor="password">
            Password
          </label>
          <PasswordField
            id="password"
            placeholder="Enter password"
            autoComplete="new-password"
            value={form.password}
            onChange={(e) => {
              setField("password", e.target.value);
            }}
          />

          {formErrors.password && (
            <p className="error">{formErrors.password}</p>
          )}
        </div>

        <div className="flex items-center justify-between mb-8">
          {/*<label>
            <input type="checkbox"
              label="Keep me logged in"
              onChange={(e) => { setRemember(e.target.value) }}
        className="mr-2"/>Keep me logged in?</label>*/}
          <Link href="/forgot">
            <a className="purple">Forgot password?</a>
          </Link>
        </div>

        <BaseButton width="100" type="submit" disabled={mutation.isLoading}>
          {loginState}
        </BaseButton>
      </form>
      <div className="flex items-center mt-8">
        <div className="mr-4">Don&apos;t have an account?</div>
        <div>

          <Link href="/register">
      <a className="purple fontBold">Register</a>
          </Link>
        </div>
      </div>
    </BaseCard>
  );
};

export default Login;

const formDecodedError = (error) => {
  if (!error) {
    return null;
  }
  for (const [key, value] of Object.entries(errorDict)) {
    if (error.message.includes(key)) {
      return value;
    }
  }
  return error.message;
};
