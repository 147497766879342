export default function DataviewerModal({modalRef, filename, onClose, content}) {

    const styles = {
        color: 'white',
        scrollbarWidth: 'thin',

      };
    return(
        <div className='fixed top-0 left-0 w-screen h-screen bottom-0 z-40 grid place-items-center ' data-cy="modal">
        <div className='absolute bg-black inset-0 z-30 opacity-80'></div>
        <div className='relative w-full flex flex-col max-w-full h-screen overflow-y-auto z-50' ref={modalRef}>
            <div className='relative z-100 bg-black w-screen flex justify-center text-center'>
                <div className='flex justify-between items-center w-full max-w-[1280px]'>
                    <h1
                        className='py-[9.5px] max-h-[61px] overflow-auto whitespace-nowrap ml-[9.5%] mr-[1%] w-[89.5%]'
                        style={styles}>
                        filename: {filename}
                    </h1>
                    <button
                        className='cursor-pointer relative h-3 w-3 before:absolute before:inset-0 before:origin-center before:w-1 before:h-full before:bg-gradient-to-b from-purple-500 to-pink-500 before:rotate-45 after:absolute after:inset-0 after:origin-center after:w-1 after:h-full after:bg-gradient-to-b from-violet-700 to-red-700 after:-rotate-45 after:rounded before:rounded after:hover:transition-opacity after:hover:opacity-50 before:hover:transition-opacity before:hover:opacity-50 h-6 w-6 before:left-[10px] after:left-[10px] mr-[8.5%] ml-[1%]'
                        onClick={onClose}
                    ></button>
                </div>
            </div>
            <div className='flex-1 flex items-center justify-center p-4'>
                {content}
            </div>
        </div>
    </div>
    )
}
