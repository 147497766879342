import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import IconOrganization from './icons/org.svg';
import IconHome from './icons/home.svg';
import { BsGearFill } from "@react-icons/all-files/bs/BsGearFill";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "./Organization.module.css"
import { getSlug } from './contexts/User';

function OrgItem(props) {
	return (
		<>
			<Link href={props.link}>
				<Dropdown.Item className={styles.link} href={props.link} >
					{props.name}
				</Dropdown.Item>
			</Link>
			<Dropdown.Divider />
		</>
	);
}

function redirect(whereToGo, fullURL) {
	let index = fullURL.indexOf(whereToGo) === 1
		? fullURL.slice(0, fullURL.indexOf(whereToGo) + 1).reduce((acc, val) => acc += `/${val}`, '') + "/runs"
		: fullURL.slice(0, fullURL.indexOf(whereToGo) + 1).reduce((acc, val) => acc += `/${val}`, '')
	return index.toString()
}

const Organization = (props) => {
	const modalRef = useRef()
	const buttonRef = useRef()
	const [showList, setShowList] = useState(false)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	useEffect(() => {
		const handleEsc = (event) => {
			if (event.keyCode === 27) {
				setShowList(false)
			}
		}
		window.addEventListener('keydown', handleEsc)
		return () => {
			window.removeEventListener('keydown', handleEsc)
		}
	}, [])

	function closeModal(e) {
		e.stopPropagation()
		if (modalRef.current) {
			if (modalRef.current !== e.target && !modalRef.current.contains(e.target) && !buttonRef.current.contains(e.target)) {
				setShowList(false)
			}
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', closeModal)
		return () => document.removeEventListener('mousedown', closeModal)
	}, [])

	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth)
		}
		window.addEventListener("resize", handleResize)
		handleResize()
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	let items = [];
	const selectedOrg = props.selectedOrg?.display_name;

	if (!selectedOrg) {
		return null;
	}

	if (props.user && props.user.organizations) {
		let orgs = props.user.organizations;
		items = orgs.map((org) => {
			const link = "/" + getSlug(org.name);
			return (
				<OrgItem key={org.name} name={org.name} display_name={org.display_name} link={link} />
			)
		})
		items.push(
			<Dropdown.Item key={"manage"} className={styles.item}>
				<span>
					<BsGearFill className={styles.gear} />
				</span>
				<span className={styles.manage}>
					Manage Organizations
				</span>
			</Dropdown.Item>
		)
	}
	const currentURL = window.location.pathname.split('/').splice(1)
	return (
		<>
			<div className={`relative flex items-center text-base ${props?.tempS3Key?.navDisabled && 'pointer-events-none'}`}>
				<div
					className={`cursor-pointer text-violet-700 flex items-center px-1 pr-1 py-2 hover:bg-purple-highlight rounded select-none ${showList && " bg-purple-100"}`}
					ref={buttonRef}
					onClick={() => setShowList(!showList)}>
					<div className="pr-1 text-xl mx-1">
						<IconOrganization />
					</div>
					<span className={`text-sm font-medium whitespace-nowrap ${currentURL.slice(1).length < 1 ? "text-purple" : "text-purple-darkgrey"}`}>
						{selectedOrg}
						<i className={"fa-solid fa-angle-down ml-2 mr-0.5 duration-150 " + `${showList ? 'rotate-180' : 'rotate-0'}`}></i>
					</span>

				</div>
				{window.innerWidth > 450 && <div className="flex text-sm">
					<div className='flex items-center justify-center'>
						<Link href={'/'}>
							<a className="block p-2 text-lg hover:bg-transparent cursor-pointer translate-y-[-1.5px]">
								<IconHome />
							</a>
						</Link>
					</div>
					{window.innerWidth > 600 && <>
						{currentURL.slice(1).map((dir, idx) => {
							return (
								(dir === 'new' && idx === 0) ?
									<div key={dir} className='flex items-center justify-center text-sm font-medium text-purple'>
										<span className="px-0.5 text-sm cursor-default">
											/
										</span>
										<Link href={window.location.pathname}>
											<a className="p-2 hover:bg-transparent rounded cursor-pointer capitalize">
												Create Project
											</a>
										</Link>
									</div>
									:
									dir === 'projectdetails' ?
										<div key={dir} className='flex items-center justify-center text-sm font-medium text-purple'>
											<span className="px-0.5 text-sm cursor-default">
												/
											</span>
											<Link href={redirect(dir, currentURL)}>
												<a className="px-2 hover:bg-transparent rounded cursor-pointer capitalize h-5 overflow-hidden">
													{props?.aliasKeys[props?.selectedProject?.name] ? props.aliasKeys[props.selectedProject?.name] :
														props?.selectedProject?.alias ? props.selectedProject.alias :
															decodeURI(currentURL[1])
													}
												</a>
											</Link>
										</div>
										:
										dir !== currentURL[0] &&

										<div key={dir} className='flex items-center justify-center text-sm font-medium text-purple'>
											<span className="px-0.5 text-sm cursor-default">
												/
											</span>
											<Link href={redirect(dir, currentURL)}>
												<a className="p-2 hover:bg-transparent rounded cursor-pointer capitalize">
													{decodeURI(dir)}
												</a>
											</Link>
										</div>
							)
						})}
					</>}
				</div>}
				{showList && <div ref={modalRef} className="absolute bottom-0 flex flex-col left-0 translate-y-full border shadow text-base bg-white shadow-md rounded min-w-max border border-solid border-violet-100 p-1"
					onClick={() => setShowList(false)}>
					<div className='flex flex-col max-h-[300px] overflow-scroll'>
						<p className='text-xs pt-1 text-slate-500 px-1'>Your organizations</p>
						{items.slice(0, items.length - 1).map((item, i) => {
							return (
								<Link key={i} href={item.props.link}>
									<div className='p-1 cursor-pointer hover:bg-violet-50 rounded' >{item.props.display_name || item.props.name}</div>
								</Link>
							)
						})}
					</div>
					<div className='bg-violet-100 h-[1px] w-full my-1'></div>
					<Link href={'/org/join'}>
						<div className='p-1 cursor-pointer hover:bg-violet-50 rounded flex items-center gap-4 justify-between' >
							<p>Join Organization</p>
							<i className="fa-solid text-sm text-slate-400 fa-plus"></i>
						</div>
					</Link>
					<Link href={'/org'}>
						<div className='p-1 cursor-pointer hover:bg-violet-50 rounded flex items-center gap-4 justify-between' >
							<p>Manage Organizations</p>
							<i className="fa-solid text-sm text-slate-400 fa-chevron-right"></i>
						</div>
					</Link>
				</div>}
			</div>
		</>
	)
}

export default Organization;
