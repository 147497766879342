import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand, ListObjectsV2Command, HeadObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';


const exports = {};
const defaultBucket = process.env.NEXT_PUBLIC_AWS_S3_BUCKET;

exports.convertDoubleSlashes = function (url) {
  return url.replace(/\/{2,}/g, '/');
}

exports.getAllFilesInBucket = async function (prefix, client, bucket = defaultBucket) {
  const command = new ListObjectsV2Command({
    Bucket: defaultBucket,
    MaxKeys: 1000,
    Prefix: prefix,
  });

  let files = [];
  try {
    let isTruncated = true;

    while (isTruncated) {
      const { Contents, IsTruncated, NextContinuationToken } = await client.send(command);
      Contents.map((c) => files.push(c));
      isTruncated = IsTruncated;
      command.input.ContinuationToken = NextContinuationToken;
    }
  } catch (err) {
    //console.error(err);
    throw err;
  }
  return files;
}



exports.downloadS3Objects = async function (keys, client, bucket = defaultBucket, parse = true, name) {
  if (!client) { return null }

  keys.forEach(async key => {
    key = exports.convertDoubleSlashes(key)

    const res = await client.send(new GetObjectCommand({
      Bucket: bucket,
      Key: key
    }))
    // const data = await res.json()
    let parsedObjects = new Response(res.Body, {})
    if (parse) {
      parsedObjects = await parsedObjects.json()
    }

    const blob = await parsedObjects.blob()
    const href = URL.createObjectURL(blob)

    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    a.href = href
    a.download = name
    a.click()
  })
}

// Fetches and parses json objects from S3 then returns the
// parsed results. Takes a string or an array of keys.
exports.getS3Objects = async function (keys, client, bucket = defaultBucket, parse = true) {

  if (!client) { return null }
  let parsedObjects = [], unparsedObjects = [];

  if (typeof keys === 'string') {
    keys = exports.convertDoubleSlashes(keys)
    const response = await client.send(new GetObjectCommand({
      Bucket: bucket,
      Key: keys
    }));
    parsedObjects = new Response(response.Body, {});
    if (parse) {
      parsedObjects = await parsedObjects.json();
    }
    return parsedObjects;
  }

  // At this point, keys was passed as an array

  keys.forEach(key => {
    key = exports.convertDoubleSlashes(key)
    unparsedObjects.push(client.send(new GetObjectCommand({
      Bucket: bucket,
      Key: key
    })));
  });

  const unparsedResults = await Promise.allSettled(unparsedObjects);

  unparsedResults.forEach(async r => {
    if (r.status != "rejected") {
      let newResponse = new Response(r.value.Body, {}).json()
      parsedObjects.push(newResponse)
    }
  });

  const parsedResults = await Promise.all(parsedObjects);
  return parsedResults;
}

exports.getLastModified = async function (key, client, bucket = defaultBucket) {
  if (!client) { return null }
  key = exports.convertDoubleSlashes(key)
  // let time = null
  const res = await client.send(new HeadObjectCommand({
    Bucket: bucket,
    Key: key,
  }))
  const time = await res.LastModified
  return time
}

exports.getObject = async function (keys, client, bucket = defaultBucket) {
  if (!client) { return null }

  const response = await client.send(new GetObjectCommand({
    Bucket: bucket,
    Key: keys,
    ResponseCacheControl: "max-age=0, no-cache, must-revalidate",
  }));

  const newResponse = new Response(response.Body, {});
  const jsonData = await newResponse.json();
  return jsonData;
}

exports.getPresignedUrl = async function (key, client, bucket = defaultBucket) {


  if (!client) { return null }

  key = exports.convertDoubleSlashes(key)
  const command = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
  });
  const url = await getSignedUrl(client, command);
  return url;
}

exports.uploadToS3 = async function (key, client, bucket, fileContent) {
  await client.send(new PutObjectCommand({
    Bucket: bucket,
    Key: key,
    Body: fileContent,
    Metadata: {
      name: fileContent.name,
    }
  }))
}

/**
 * Get s3://bucket/prefix size in bytes
 */
export const getS3PrefixSize = async ({ client, bucket, prefix }) => {
  if (!client) { return null }


  let size = 0;
  let continuationToken = undefined;

  do {
    const response = await client.send(
      new ListObjectsV2Command({
        Bucket: bucket,
        Prefix: prefix,
        ContinuationToken: continuationToken
      })
    )
    size = response.Contents?.reduce((sum, file) => sum + file.Size, size) || 0;
    continuationToken = response.NextContinuationToken;
  } while (continuationToken)

  return size;
}

exports.getS3PrefixSize = getS3PrefixSize;

export default exports;
