import "../styles/customTheme.scss";
import "../styles/globals.css";
import "../styles/overrides.css";
import "../components/error-boundaries/RootErrorBoundary";

// NOTE: This is here to initialize Mixpanel on app load
import "../mixpanel";

/*eslint-disable */
// HACK: These imports fix a CSS module load issue. Do not delete!
import Register from "../components/User/Register";
import ForgotPassword from "../components/User/ForgotPassword";
import ResetPassword from "../components/User/ResetPassword";
import TermsAndConditions from "../components/ztn/TermsAndConditions";
/*eslint-enable */

import AsyncQueryProvider from "../components/ztn/contexts/AsyncQuery";
import AuthLayout from "../components/Layouts/AuthLayout";
import RootErrorBoundary from "../components/error-boundaries/RootErrorBoundary";
import { useRouter } from "next/router";

const nonAuthRoutes = [
  "/register",
  "/forgot",
  "/terms-and-conditions",
  "/trial",
  "/trial/[token]",
  "/reset/[token]",
];

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  if (nonAuthRoutes.includes(router.pathname)) {
    return (
      <RootErrorBoundary>
        <AsyncQueryProvider>
          <Component {...pageProps} />
        </AsyncQueryProvider>
      </RootErrorBoundary>
    );
  } else {
    return (
      <RootErrorBoundary>
        <AsyncQueryProvider>
          <AuthLayout>
            <Component {...pageProps} />
          </AuthLayout>
        </AsyncQueryProvider>
      </RootErrorBoundary>
    );
  }
}

export default MyApp;
