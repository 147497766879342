import EmbeddedUser from "../User/Embedded"
import Organization from './Organization'
import ZetaneLogo from './icons/zetane-logo.svg'
import * as styles from './header.module.css'
import Router from "next/router"

const Header = (props) => {
  return (
    <header className="fixed top-0 z-40 h-24 w-full flex">
      <ZetaneLogo onClick={() => Router.push('/')} className={`${styles.icon} cursor-pointer ${props?.tempS3Key?.navDisabled && 'pointer-events-none'}`} />
      <Organization {...props} aliasKeys={props?.tempS3Key?.aliasKeys}/>

      <div className={styles.settingsContainer}>
        {/* <div className={styles.iconContainer}>
          <SearchButton />
        </div>
        <div className={styles.iconContainer}>
          <ThemeToggle alt="Toggle Theme" />
        </div>
        <div className={styles.iconContainer}>
          <NotificationBell badge='4' />
        </div> */}
        <div className={`px-5 ${styles.iconContainer}`}>
          <EmbeddedUser {...props} />
        </div>
      </div>
    </header>
  )
}

export default Header
