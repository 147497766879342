import create from 'zustand'
import produce from 'immer'

export const useHelpState = create((set, get) => ({
    showHelpModal: false,
    link: '',
    errorMessage: '',
    openHelpHandler: (message, link) => {
        set(produce(state => {
            state.showHelpModal = true
            state.errorMessage = message
            state.link = link
        }))
    },
    closeHelpHandler: () => {
        set(produce(state => {
            state.showHelpModal = false
            state.errorMessage = ''
            state.link = ''
        }))
    }
}))
