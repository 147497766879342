import style from './Alert.module.scss';
import { useState, useEffect } from 'react';

export default function Alert(props) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    props.show === undefined ? setShow(true) : setShow(props.show);
  }, [props.show]);

  const {
    title,
    variant = 'success', // 'success', 'error', 'warning'
    showIcon = false,
    showButton = false,
    onClose = () => setShow(false)
  } = props;

  return (
    <>
    {show &&
      <div className={style[variant + '-container'] + ' ' + props.className + ' relative' + (props?.list && ' min-h-[100px]')}>
        {showIcon &&
          <div className={`${style['icon-container']}`}>
            {/* TODO: Show appropriate icon depending on variant */}
            <img src="/cancel.svg" />
          </div>
        }
        <div className={style.content}>
          {title &&
            <header className={style.header}>
              <div className={style[variant + '-title']}>{title}</div>
            </header>
          }
          <div className={style[variant + '-text']}>
            {props.children}
          </div>
        </div>
        {showButton &&
          <div className={`${style['button-container']}`}>
            <img src="/x.svg" onClick={onClose}/>
          </div>
        }
        {props?.list &&
        <>
          <div className={`grid place-items-center px-2 bg-transparent absolute text-[2rem] top-1/2 left-[0.5%]`}>
              <i onClick={() => props.updateMessage(props?.index-1)} className={`fa-solid fa-chevron-left ${props?.index === 0 ? 'text-[#8D8D8E] cursor-default' : 'text-violet-800 cursor-pointer'}`}></i>
          </div>
          <div className={`grid place-items-center px-2 bg-transparent absolute text-[2rem] top-1/2 right-[0.5%]`}>
              <i onClick={() => props.updateMessage(props?.index+1)} className={`fa-solid fa-chevron-right ${props?.index === props?.items - 1 ? 'text-[#8D8D8E] cursor-default' : 'text-violet-800 cursor-pointer'}`}></i>
          </div>
        </>}

      </div>
    }
    </>
  );
}
