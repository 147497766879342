// localStorage (persists across tabs and windows; until explicitly deleted)

export const getLocalStorage = (key, default_value) => {
  try {
    const state = window.localStorage.getItem(key);
    return state ? JSON.parse(state) : default_value;
  } catch (e) {
    // console.error(e);
    return default_value;
  }
}

export const setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    // console.error(e);
  }
}


// sessionStorage (like localStorage per tab; survives refresh; expires on tab close)

export const getSessionStorage = (key, default_value) => {
  try {
    const state = window.sessionStorage.getItem(key);
    return state ? JSON.parse(state) : default_value;
  } catch (e) {
    //console.error("Error fetching from session storage, returning default instead: ", e);
    return default_value;
  }
}

export const setSessionStorage = (key, value) => {
  try {
    const stringVal = JSON.stringify(value);
    window.sessionStorage.setItem(key, stringVal);
  } catch (e) {
    // console.error(e);
  }
}

export const clearSessionStorage = () => {
  window.sessionStorage.clear();
}
