import IconProjects from './icons/projects.svg';
import IconDataset from './icons/dataset.svg';
import IconReports from './icons/test-reports.svg';
import IconModels from './icons/models.svg';
import IconChat from './icons/chat.svg';
import IconTests from './icons/test-series.svg';
import IconContact from './icons/contact.svg'
import IconJobs from './icons/hammer-icon.svg'
import IconAugment from './icons/augmenter.svg'
import IconInfo from 'public/icon-info.svg'
// import IconQuestion from 'public/icon-question.svg'

import LinksList from "./LinksList"
import { getOrgName } from "./contexts/User";
import StorageMeter from './StorageMeter';
import * as styles from './menu.module.css';
import { useRef, useState, useEffect } from 'react';

const Menu = (props) => {
  const [showList, setShowList] = useState(false);
  const buttonRef = useRef(null);
  const listRef = useRef(null);
  const [reportList, setReportList] = useState('Test & Evaluation');
  const orgSlug = getOrgName(props.user, props.selectedOrg.id);
  const orgRoot = orgSlug ? ("/" + orgSlug) : orgSlug;
  let projectRoot;
  if (props.selectedProject && props.selectedProject.name) {
    projectRoot = orgRoot + "/" + props.selectedProject.name;
  }

  const navContent = {
    'Project Information':
      <LinksList
        links={[
          {
            title: "Project Information",
            link: projectRoot + "/projectdetails",
            icon: IconInfo,
          }
        ]}
      />,
    'Test & Evaluation':
      <LinksList
        disableLink={props?.tempS3Key?.navDisabled}
        handleSelection={handleSelection}
        heading='Test & Evaluation'
        links={[
          // {
          //   title: "Projects",
          //   link: orgRoot + "/",
          //   icon: IconProjects,
          // },
          {
            title: "Test Reports",
            link: projectRoot + "/runs",
            icon: IconReports
          },
          {
            title: "Models",
            link: projectRoot + "/models",
            icon: IconModels
          },
          {
            title: "Datasets",
            link: projectRoot + "/datasets",
            icon: IconDataset
          },
          // {
          //   title: "Jobs",
          //   link: projectRoot + "/jobs",
          //   icon: IconJobs
          // },
          // {
          //   title: "Annotations",
          //   link: projectRoot + "/annotations",
          //   icon: IconDataset
          // },
          // {
          //   title: "Test Series",
          //   link: projectRoot + "/tests",
          //   icon: IconTests
          // },
        ]}
      />,
    // 'Augmentation':
    //     <LinksList
    //       disableLink={props?.tempS3Key?.navDisabled}
    //       handleSelection={handleSelection}
    //       heading='Augmentation'
    //       links={[
    //         {
    //           title: "Augmented Datasets",
    //           link: projectRoot + "/augment",
    //           icon: IconDataset
    //         },
    //       ]}
    //     />
  }

  function openInNewWindow(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) { newWindow.opener = null }
  }

  function handleSelection(report) {
    if (report !== reportList) {
      setReportList(report)
    }
  }

  function closeModal(e) {
    if (listRef.current) {
      if (listRef.current !== e.target && !listRef.current.contains(e.target) && !buttonRef.current.contains(e.target)) {
        setShowList(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeModal)
    return () => document.removeEventListener('mousedown', closeModal)
  }, [])

  if (!props.selectedOrg) { return null; }
  return (
    <nav className={`
      fixed
      top-[3.8em]
      left-0
      sm:w-[200px]
      max-w-[640px]
      w-full
      sm:h-[calc(100vh_-_60.8px)]
      h-18

      flex
      sm:flex-col
      flex-row
      ${props?.selectedProject === null ? 'justify-end' : 'justify-between'}

      color-purple-600
      z-[35]
      bg-offwhite
      border-r-[0px] sm:border-b-[1px]
      sm:border-r-[1px] sm:border-b-[0px]
      border-solid border-[var(--ztn-purple-grey)]
      shadow-[inset_0_-3px_0_0_var(--ztn-purple-grey)]
      sm:shadow-[inset_3px_0_0_0_var(--ztn-purple-grey)]
      `}>

      {props?.selectedProject?.name &&
        <>
          {/* Desktop navbar */}
          <div className={`${styles.linksContainer} sm:flex sm:flex-col hidden`}>
            {navContent['Project Information']}
            {navContent['Test & Evaluation']}
            {/* {navContent['Augmentation']} */}
          </div>

          {/* Mobile navbar */}
          <div className={`${styles.linksContainer} sm:hidden flex`}>
            {navContent['Project Information']}
            {/* <button
            ref={buttonRef}
            className={'cursor-pointer text-violet-700 flex items-end px-1 pr-1 py-2 hover:bg-purple-highlight rounded select-none' + (showList ? " bg-purple-100" : "")}
            onClick={() => setShowList(!showList)}>
            <i className={`fa-solid fa-angle-down mx-2 duration-150 ${showList ? 'rotate-180' : 'rotate-0'}`}></i>
          </button>

          {showList &&
          <ul
            className="absolute bottom-0 left-[94px] translate-y-full border border-solid border-purple-300 p-2 shadow text-base text-violet-600 bg-purple-100 min-w-max"
            ref={listRef}
            onClick={(e) => {
              setShowList(false)
              handleSelection(e.target.textContent)}}>
            <li className={`cursor-pointer p-1`}>Test & Evaluation</li>
            <li className={`cursor-pointer p-1`}>Augmentation</li>
          </ul>} */}

            {navContent[reportList]}
          </div>
        </>}

      {!props?.selectedProject?.name &&
        <div className='max-[639px]:border-b-8 max-[639px]:border-solid max-[639px]:border-[#F0F0F0] max-[639px]:w-full max-[639px]:h-[74px]'></div>}


      {/* Contact container */}
      <ul className={`flex sm:flex-col sm:justify-center sm:w-full cursor-pointer sm:border-t-[1px] sm:border-l-[1px] sm:border-[var(--ztn-purple-grey)] sm:border-solid `}>
        <li className={`${styles.listItem} flex items-center`}
          onClick={() => openInNewWindow('https://docs.zetane.com/docs/protector/')}>
          <div className={`${styles.linkStyles} ${styles.link} ${styles.contact} grid sm:p-3 sm:w-full hover:bg-purple-highlight`}>
            <i className="fa-regular fa-circle-question text-xl sm:-ml-2.5 max-[640px]:mt-[3.5px]"></i>
            {/* may consider using svg, but a tad too bold */}
            {/* <IconQuestion />  */}
            <p>Documentation</p>
          </div>
        </li>
        <li className={`${styles.listItem} flex items-center`}
          onClick={() => openInNewWindow('https://zetane.com/contact')}>
          <div className={`${styles.linkStyles} ${styles.link} ${styles.contact} grid sm:p-3 sm:w-full hover:bg-purple-highlight`}>
            <IconChat />
            <p>Contact</p>
          </div>
        </li>
      </ul>
    </nav >
  )
}

export default Menu
