import { createContext, useState, useEffect } from "react";
import { clearSessionStorage } from "../utils/persistentStorage";
import { useTrackedUser } from "./User";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import Login from "../Login";
import AccountLayout from "../../Layouts/AccountLayout";

const Auth = createContext({
  token: undefined,
  toggleLogin: () => {},
  getAuthHeaders: () => {},
});

function getLoggedIn() {
  return Cookies.get("ztoken");
}

const getDevWebsiteDomain = () => {
  if (process.env.NEXT_PUBLIC_IS_STAGING) {
    return "https://staging-support.zetane.com";
  }
  if (process.env.NEXT_PUBLIC_IS_PROD) {
    return "https://docs.zetane.com";
  }

  return "http://localhost:3001";
};
export const getProtectorBaseDomain = () => {
  if (process.env.NEXT_PUBLIC_IS_STAGING) {
    return ".zetane.com";
  }
  if (process.env.NEXT_PUBLIC_IS_PROD) {
    return ".zetane.com";
  }

  return "localhost";
};

const appIDs = {
  "e3583fd4-18d5-11ed-861d-0242ac120002": getDevWebsiteDomain(),
};
function redirectToApp(referee, route) {
  window.location.href = appIDs[referee] + route;
}
function setLoggedIn(token, referee, isValidReferee, route) {
  // logout passes undefined which is falsy
  if (token) {
    Cookies.set("ztoken", token, {
      expires: 7,
      domain: getProtectorBaseDomain(),
    });
    if (isValidReferee) {
      redirectToApp(referee, route);
    }
  } else {
    clearSessionStorage();
    Cookies.remove("ztoken", { domain: getProtectorBaseDomain() });
    Cookies.remove("zuser", { domain: getProtectorBaseDomain() });
  }
}

export const AuthProvider = (props) => {
  const [user, setUserState] = useTrackedUser();
  const [token, setToken] = useState(getLoggedIn());
  const [readyToRender, setReadyToRender] = useState(token);
  const router = useRouter();
  const { referee, route } = router.query;
  // Have to check the previous value and if we have a new token, we update it
  const isValidReferee = appIDs[referee];
  useEffect(() => {
    let old_token = Cookies.get("ztoken");
    if (old_token != token) {
      setLoggedIn(token, referee, isValidReferee, route);
      if (!isValidReferee) {
        setReadyToRender(token);
      }
    }
  }, [token]);

  const toggleLogin = (token) => {
    setToken(token);
    if (!token) {
      setUserState({});
    }
  };

  const getAuthHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  if (token === "undefined" || token === undefined || !token) {
    const nonAuthRoutes = [
      /^\/register/,
      /^\/forgot/,
      /^\/trial/,
      /^\/start-trial/,
      /^\/terms-and-conditions/,
      /^\/reset/,
    ];
    const match = nonAuthRoutes.some((rx) => rx.test(router.asPath));
    if (router.pathname == "/404" && match) {
      return (
        <Auth.Provider value={{ toggleLogin, token, getAuthHeaders }}>
          {props.children}
        </Auth.Provider>
      );
    }
    return (
      <Auth.Provider value={{ toggleLogin }}>
        <AccountLayout>
          <Login />
        </AccountLayout>
      </Auth.Provider>
    );
  } else {
    return (
      <Auth.Provider value={{ toggleLogin, token, getAuthHeaders }}>
        {readyToRender && props.children}
      </Auth.Provider>
    );
  }
};

export default Auth;
