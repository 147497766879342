// (c) Zetane Systems
import { useQuery } from "react-query";
import { useContext, useState, useEffect } from "react";
import Auth from "../contexts/Auth";
import axios from "axios";

// Do not use interactive because it will be heavy for the backend but can be useful in very specific situations
export const _fetchPolicy = {
  frequent: {
    refetchInterval: 5000, // 5 seconds
    refetchOnWindowFocus: true,
  },
  default: {
    refetchOnWindowFocus: true,
    refetchInterval: 15 * 60 * 1000, // 15 minutes
  },
  interactive: {
    refetchInterval: 1000, // 1 second
    refetchOnWindowFocus: true,
  },
  disabled: {
    enabled: false,
  },
};

export const useAuthQuery = (url, options) => {
  const { getAuthHeaders } = useContext(Auth);
  const headers = getAuthHeaders();

  return useQueryDataArray(url, headers, options);
};

export const useAuthMutation = (url, payload) => {
  const { getAuthHeaders } = useContext(Auth);
  return axios.post(url, payload, {
    headers: { Authorization: getAuthHeaders() },
  });
};

export const queryFetcher = async (url, fetchOptions = {}) => {
  try {
    const res = await fetch(url, fetchOptions);
    if (!res.ok) {
      throw new Error("query failed (response: " + res.status + ")");
    }
    return res.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const useQueryDataArray = (
  url,
  fetchOptions = {},
  queryOptions = {}
) => {
  const defaultQueryOpts = {
    retry: false,
    refetchOnWindowFocus: false,
  };

  const fullQueryOpts = {
    ...defaultQueryOpts,
    ...queryOptions,
  };

  const queryKey = url;

  return useQuery(
    queryKey,
    () => queryFetcher(url, fetchOptions),
    fullQueryOpts
  );
};

export function useFetchPolicy2(data, update_keywords, hasDeps) {
  let fetchPolicy = _fetchPolicy.default;

  fetchPolicy['enabled'] = hasDeps

  if (data) {
    for (const item in update_keywords) {
      const keyword = update_keywords[item];
      for (const index in data) {
        const record = data[index];
        if ("upload_status" in record) {
          if (record.upload_status.status === keyword) {
            fetchPolicy = {...fetchPolicy, ..._fetchPolicy.frequent}
            return;
          }
        }
      }
    }
    fetchPolicy = {
      ...fetchPolicy,
      ..._fetchPolicy.default,
    };
  }
  return fetchPolicy;
}

export function useFetchPolicy(data, update_keywords, hadDeps) {
  const [fetchPolicy, setFetchPolicy] = useState(_fetchPolicy.default);

  useEffect(() => {
    setFetchPolicy({
      ...fetchPolicy,
      enabled: hadDeps,
    });
  }, [hadDeps]);
  useEffect(() => {
    if (data) {
      for (const item in update_keywords) {
        const keyword = update_keywords[item];
        for (const index in data) {
          const record = data[index];
          if ("upload_status" in record) {
            if (record.upload_status.status === keyword) {
              setFetchPolicy({
                ...fetchPolicy,
                ..._fetchPolicy.frequent,
              });
              return;
            }
          }
          /*
          if ("modelimage" in record) {
            if (record.modelimage) {
              // Can be null for some reason right after upload :/
              if (record.modelimage.status.status === keyword) {
                setFetchPolicy({
                  ...fetchPolicy,
                  ..._fetchPolicy.frequent,
                });
                return;
              }
            } else {
              setFetchPolicy({
                ...fetchPolicy,
                ..._fetchPolicy.default,
              });
              return;
            }
          } */
        }
      }
      setFetchPolicy({
        ...fetchPolicy,
        ..._fetchPolicy.default,
      });
    }
  }, [data]);
  return { fetchPolicy };
}
