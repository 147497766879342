import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useMutation } from "react-query";
import Link from "next/link";
import axios from "axios";
import validator from "validator";
import BaseCard from "../ui/BaseCard";
import BaseButton from "../ui/BaseButton";
import PasswordField from "../ui/PasswordField";
import AccountLayout from "../Layouts/AccountLayout";
import Alert from "../ui/Alert";
import Cookies from "js-cookie";
import mixpanel from "../../mixpanel";
import { ClassNames } from "@emotion/react";
import styles from "./Register.module.css";
const errorDict = {
  400: "Email is invalid or already taken.",
};

function setLoggedIn(token) {
  // logout passes undefined which is falsy
  if (token) {
    Cookies.set("ztoken", token, { expires: 7 });
  } else {
    clearSessionStorage();
    Cookies.remove("ztoken");
  }
}

const Register = () => {

  const router = useRouter();

  const [form, setForm] = useState({ email: "", password: "" , password2: ""});
  const [formErrors, setFormErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!formErrors[field]) {
      setFormErrors({
        ...formErrors,
        [field]: "",
      });
    }
  };

  useEffect(() => {
    mixpanel.track("View Register Page");
  }, []);

  const validateForm = () => {
    const email = form.email || "";
    const password = form.password || "";
    const password2 = form.password2 || "";
    const agreedToTerms = form.agreedToTerms;
    const errors = {};

    if (validator.isEmpty(email))
      errors.email = "Please enter an email address";
    else if (!validator.isEmail(email))
      errors.email = "Please enter a valid email address";

    if (validator.isEmpty(password))
      errors.password = "Please enter a password";
    else if (!validator.isLength(password, { min: 8 }))
      errors.password = "Please use at least 8 characters";
    else if (!validator.isStrongPassword(password))
      errors.password =
        "Password must contain at least one number, one special character, and have a mixture of uppercase and lowercase letters";

    if (!agreedToTerms)
      errors.agreedToTerms =
        "Please indicate that you have read and agreed to the Terms & Conditions";
    if (password !== password2)
      errors.password =
        "Your passwords do not match. Please check them and try again"
    return errors;
  };

  const mutation = useMutation(
    (event) => {
      event.preventDefault();

      const errors = validateForm();

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);

        throw new Error(
          "This form has errors. Please fix them before proceeding."
        );
      }
      return axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/register`,
        {
          username: form.email,
          email: form.email,
          password: form.password,
        },

      );
    },
    {
      onError: (error, variables, context) => {
        console.error(error);
      },
      onSuccess: (data, variables, context) => {
        const { user_id, token } = data.data;
        mixpanel.identify(user_id);
        mixpanel.people.set_once();
        setLoggedIn(token);
        router.push("/");
      },
    }
  );

  return (
    <AccountLayout>

      <BaseCard title="Create Account">
        <Alert
          className="mb-4"
          show={mutation.isError && !mutation.isLoading}
          variant="error"
        >
          {formDecodedError(mutation.error)}
        </Alert>
        <form role="form" onSubmit={mutation.mutate}>
          <div className="mb-10">
          <div className="p-3 border-[1.5px] mb-4 border-solid border-emerald-400 bg-emerald-100 text-center" >
          Get $50 in free credits when you sign up!
      </div>
            <label className="block text-gray-500  mb-3" htmlFor="username">
              Email
            </label>
            <input
              className="border border-solid border-gray-300 focus:border-gray-300 focus:ring-0 bg-transparent rounded w-full py-3 px-6 text-gray-700"
              id="username"
              type="text"
              placeholder="Enter email address"
              value={form.email}
              onChange={(e) => {
                setField("email", e.target.value);
              }}
            />
            {formErrors.email && <p className="error">{formErrors.email}</p>}
          </div>
          <div className="mb-10">
            <label className="block text-gray-500 mb-3" htmlFor="password">
              Password
            </label>
            <PasswordField
              id="password"
              placeholder="Enter password"
              autoComplete="new-password"
              value={form.password}
              onChange={(e) => {
                setField("password", e.target.value);
              }}
            />

            {formErrors.password && (
              <p className="error">{formErrors.password}</p>
            )}
          </div>
          <div className="mb-10">
            <label className="block text-gray-500 mb-3" htmlFor="password">
              Confirm Your Password
            </label>

            <PasswordField
              id="password2"
              placeholder="Enter password"
              autoComplete="new-password"
              value={form.password2}
              onChange={(e) => {
                setField("password2", e.target.value);
              }}

            />
            {formErrors.password && (
              <p className="error">{formErrors.password}</p>
            )}


          </div>
          <div className="mb-10">
            <label>
              <input
                type="checkbox"
                onChange={(e) => setField("agreedToTerms", e.target.checked)}
                className={classNames(
                  "mr-2 shadow-sm rounded-sm border-2 active:ring-purple focus:ring-purple text-purple ",
                  styles["checkbox-input"]
                )}
              />
              Agree to{" "}
              <Link href="/terms-and-conditions">
                <a className="purple fontBold text-underline">
                  Terms & Conditions
                </a>
              </Link>
            </label>
            {formErrors.agreedToTerms && (
              <p className="error">{formErrors.agreedToTerms}</p>
            )}
          </div>

          <BaseButton width="100" type="submit" disabled={mutation.isLoading}>
            Sign Up
          </BaseButton>
        </form>
        <div className="flex items-center mt-8">
          <div className="mr-4">Already have an account?</div>
          <div>
            <Link href="/login">
              <a className="purple fontBold">Login</a>
            </Link>
          </div>
        </div>
      </BaseCard>
    </AccountLayout>
  );
};

export default Register;

const formDecodedError = (error) => {
  if (!error) {
    return null;
  }
  for (const [key, value] of Object.entries(errorDict)) {
    if (error.message.includes(key)) {
      return value;
    }
  }
  return error.message;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
