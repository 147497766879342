import { useState } from "react";

export default function PasswordField(props) {
  const [type, setType] = useState("password");
  //const [ input, setInput ] = useState(null);

  const showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setType(type === "text" ? "password" : "text");
    // FIXME: This sets focus to the input field when clicking visibility button.
    // However, it gets a null value when using `inputRef=(setInput)` in
    // FormControl element.
    //input.focus();
  };

  return (
    <div className="relative border border-solid border-gray-300 rounded w-full py-3 px-6 text-gray-700">
      <input
        className="w-11/12 bg-transparent p-0 ring-0 focus:ring-0"
        type={type}
        {...props}
      />
      <img
        src={type === "text" ? "/eye-close.svg" : "/eye-open.svg"}
        onClick={showHide}
        className="absolute top-4 right-4 cursor-pointer w-6 h-4"
      />
    </div>
  );
}
