import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useRouter } from "next/router";
import Auth from "./ztn/contexts/Auth";
import { useTrackedUser, getDefaultOrg, createProjectObject } from "./ztn/contexts/User";
import useSessionStorage from "./ztn/utils/useSessionStorage";
import { useAuthQuery } from "./ztn/utils/queryUtils";
import MainLayout from "./Layouts/MainLayout";

const syncRouterWithSelectedProject = (query, selectedOrg) => {
    if (query.project) {
        const projectSlug = query.project;
        if (selectedOrg && selectedOrg.projects) {
            return createProjectObject(selectedOrg, projectSlug)
        }
    }
    return null;
}

const setDefaultOrg = (user, setSeletedOrg) => {
    const org = getDefaultOrg(user);
    if (org) {
        setSeletedOrg(org);
    }
}

const userHasOrg = (user, orgSlug) => {
    if (!orgSlug) { return null }
    if (user && user.organizations) {
        return user.organizations.find((org) => (orgSlug.toLowerCase() == org.name.toLowerCase()))
    }
    return null;
}

const RootComponent = (props) => {
    const router = useRouter();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const projectToDelete = useRef('');
    const { toggleLogin, token, getAuthHeaders } = useContext(Auth);
    const [user, setUserState] = useTrackedUser();
    const [selectedOrg, setSelectedOrg] = useState(useSessionStorage('selectedOrg', null));
    const [tempS3Key, setTempS3Key] = useState({
        descriptionKeys: {},
        imageKeys: {},
        aliasKeys: {},
        deletedProjectList: {},
        defaultImageKeys: {},
        navDisabled: false
    })

    const { isLoading, isError, data, error, refetch: refetchUser } = useAuthQuery(`${process.env.NEXT_PUBLIC_API_URL}/users/me`);

    const handleUpdateS3Keys = (type, project, key) => {
        setTempS3Key(prev => {
            const newState = {...prev};
            if (type === 'navDisabled') {
                newState[type] = key;
            } else {
                newState[type][project] = key;
            }
            return newState;
        })
    }

    if (isError) {
        toggleLogin(undefined);
    }

    const onProjectDelete = projectName => {
        projectToDelete.current = projectName;
        setIsConfirmDialogOpen(true);
    };

    async function deleteProject(event, projectName) {
        setIsConfirmDialogOpen(false);

        projectName = projectName || projectToDelete.current;

        const url = encodeURI(`${process.env.NEXT_PUBLIC_API_URL}/api/${selectedOrg.name}/${projectName}/delete`);

        const response = await fetch(url, {
            method: 'DELETE',
            headers: getAuthHeaders().headers,
        });

        if (!response.ok) throw new Error(`Server responded with a ${response.status} error while attempting to delete a project: ${projectName}`);

        refetchUser();
    };

    useEffect(() => {
        if (data) {
            data.refetch = refetchUser;
            setUserState((prev) => (data));
        }
    }, [data, user])

    useEffect(() => {
        if (selectedOrg) {
            let redirect = false
            if (router.query.org) {
                const userQueryOrg = userHasOrg(user, router.query.org)
                if (userQueryOrg) {
                    // do we need to check selectedOrg == routerOrg
                    // or will the dep array do that for us?
                    setSelectedOrg(userQueryOrg)
                    return
                } else {
                    redirect = true
                }
            }
            const userSelectedOrg = userHasOrg(user, selectedOrg.name)
            if (userSelectedOrg) {
                if (redirect) {
                    const selectedUrl = '/' + userSelectedOrg.name
                    router.push(selectedUrl)
                }
                return
            } else {
                setDefaultOrg(user, setSelectedOrg)
            }
        } else {
            let redirect = false
            if (router.query.org) {
                const userQueryOrg = userHasOrg(user, router.query.org)
                if (userQueryOrg) {
                    setSelectedOrg(userQueryOrg)
                    return
                } else {
                    redirect = true
                }
            }
            if (redirect) {
                const selectedUrl = '/'
                router.push(selectedUrl)
            }
            setDefaultOrg(user, setSelectedOrg);
        }
    }, [router, router.query, user, selectedOrg, setSelectedOrg])

    const selectedProject = useMemo(() => syncRouterWithSelectedProject(router.query, selectedOrg), [router.query.project, selectedOrg]);


    return (
        <>
            <MainLayout
                user={user}
                selectedOrg={selectedOrg}
                selectedProject={selectedProject}
                deleteProjectHandler={onProjectDelete}
                {...props}
                handleUpdateS3Keys={handleUpdateS3Keys}
                tempS3Key={tempS3Key}
            />
        </>
    )
}

export default RootComponent;
