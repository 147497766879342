// (C) Zetane Systems

import { useRouter } from 'next/router'
import Link from 'next/link';
import * as styles from './menu.module.css'

const normalize = url => decodeURIComponent(url).replace(/\/$/, '').toLowerCase();

const LinkWithBadge = ({ href = '/', children, disableLink }) => {
  const router = useRouter();
  const normalHref = normalize(href)
  const normalRoute = normalize(router.asPath)
  const isActive =
    (normalRoute === normalHref) ||
    (normalHref.split('/').length > 2 && normalRoute.startsWith(normalHref));

  const activeStyle = isActive
    ? `${styles.activeLink}
      sm:shadow-[inset_3px_0_0_0_var(--ztn-purple)]`
    : `${styles.link}

      sm:shadow-[inset_3px_0_0_0_var(--ztn-purple-grey)]`
      // shadow-[inset_0_-3px_0_0_var(--ztn-purple-grey)]

      // styles.activeLink
      // shadow-[inset_0_-3px_0_0_var(--ztn-purple)]
  return (
    <Link href={href}>
      <a className={`${activeStyle} ${styles.linkStyles} sm:p-3 sm:w-full hover:bg-[var(--ztn-purple-grey)] hover:bg-purple-highlight ${disableLink && 'pointer-events-none'}`}>
        {children}
      </a>
    </Link >
  )
}

export default LinkWithBadge
