import React, { useState, useEffect, useContext } from 'react'
import Auth from "components/ztn/contexts/Auth"

function Minimalist(props) {
    const { rows, cols, setAddIndex, currIndexes } = props

    function handleToggle(rowIndex) {
        return () => setAddIndex([...new Set([
            ...(currIndexes.includes(rowIndex) ? currIndexes.filter(val => val !== rowIndex) : [...currIndexes, rowIndex])
        ])])
    }
    return (
        <div className='flex px-4 pt-1 items-stretch max-w-full overflow-auto'>
            {cols.map((col, colIndex) => {
                return <div key={colIndex} className='flex flex-col gap-2 min-w-max flex-1'>
                    <h3 className='px-2 text-sm border-b border-solid border-violet-700'>{col.headerName}</h3>

                    {rows.map((row, rowIndex) => {
                        return <div className={'py-1 px-2 flex items-center' + (currIndexes.includes(rowIndex) ? ' bg-violet-200' : '')} key={rowIndex}>
                            {colIndex === 0 && <div onClick={handleToggle(rowIndex)} className={'h-3 w-3 cursor-pointer duration-300 hover:bg-violet-500 border-violet-700 border border-solid mr-2' + (currIndexes.includes(rowIndex) ? ' bg-violet-400' : '')}>
                            </div>
                            }
                            {row[col.field] ?? <p className='text-transparent'>filler text</p>}
                        </div>

                    })}
                </div>
            })}


        </div >
    )
}

function DetailsTable(props) {
    const { setFunctions, items, showDetails, selectedVals } = props
    const columns = ['Dataset', 'Model', 'Project', 'Run'].reduce((acc, curr) => {
        return {
            ...acc, [curr]: [
                { field: "id", headerName: "ID" },
                { field: "name", headerName: `${curr} Name` },
                { field: "size", headerName: "Size" }
            ]
        }
    }, {})

    const rowData = {
        models: [],
        datasets: [],
        runs: [],
        projects: []
    }

    const mapping = {
        0: 'models',
        1: 'datasets',
        2: 'runs',
        3: 'projects'
    }

    for (const [key, value] of Object.entries(mapping)) {
        for (const val of Object.values(items[key])) {
            rowData[value].push({
                id: val.id,
                name: val.name
            })
        }
    }

    return (
        <>
            {Object.keys(rowData).map(element => {
                return <div key={element} className="py-2">
                    <h4 className='capitalize text-xl pb-1'>{element}</h4>
                    {(rowData[element].length > 0 && showDetails) ? (
                        <Minimalist rows={rowData[element]} cols={columns[element.slice(0, 1).toUpperCase() + element.slice(1, -1)]} currIndexes={selectedVals[element]} setAddIndex={setFunctions[element]}
                        />
                    ) : rowData[element].length > 0 ?
                        (
                            <p>There are {rowData[element].length} {element} in your recycling bin.</p>
                        ) :
                        (
                            <p>There are no {element} in the recycle bin.</p>
                        )
                    }
                </div>
            })}
        </>
    )
}


export default function RestoreDelete(props) {
    const { user } = props
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [error, setError] = useState(null)
    const { getAuthHeaders } = useContext(Auth)
    const [isLoading, setIsLoading] = useState(true)
    const [selectedModels, setSelectedModels] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([])
    const [selectedRuns, setSelectedRuns] = useState([])
    const [selectedDatasets, setSelectedDatasets] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [items, setItems] = useState([])

    const setFunctions = {
        'models': setSelectedModels,
        'datasets': setSelectedDatasets,
        'runs': setSelectedRuns,
        'projects': setSelectedProjects
    }

    const selectedVals = {
        'models': selectedModels,
        'datasets': selectedDatasets,
        'runs': selectedRuns,
        'projects': selectedProjects
    }


    async function handleSubmit(type) { //type is either restore or delete
        let datasets_ids = []
        let models_ids = []
        let runs_ids = []
        let projects_ids = []

        for (const value of Object.values(showDetails ? selectedModels : items[0])) {
            models_ids.push(showDetails ? items[0][value].s3_key : value.s3_key)
        }
        for (const value of Object.values(showDetails ? selectedDatasets : items[1])) {
            datasets_ids.push(showDetails ? items[1][value].s3_key : value.s3_key)
        }
        for (const value of Object.values(showDetails ? selectedRuns : items[2])) {
            runs_ids.push(showDetails ? items[2][value].s3_key : value.s3_key)
        }
        for (const value of Object.values(showDetails ? selectedProjects : items[3])) {
            projects_ids.push(showDetails ? items[3][value].name : value.name)
        }

        const org = props.selectedOrg.name
        const url = `${process.env.NEXT_PUBLIC_API_URL}/api/${org}/${type === 'restore' ? 'restore_items' : 'empty_trash'}`

        try {
            const res = await fetch(url, {
                method: (type === 'restore' ? "PATCH" : "DELETE"),
                headers: getAuthHeaders().headers,
                body: JSON.stringify({
                    models_ids,
                    datasets_ids,
                    runs_ids,
                    projects_ids,
                    user
                }),
            })
        } catch (err) {
            setError(err.message)
            console.log(err)
            throw Error(`${err.status}: ${err.message}`)
        } finally {
            setShowConfirmation(true)
            fetchItems()
        }
    }

    async function fetchItems() {
        const org = props.selectedOrg.name
        const url = `${process.env.NEXT_PUBLIC_API_URL}/api/${org}/empty_trash`
        try {
            const res = await fetch(url, {
                method: "GET",
                headers: getAuthHeaders().headers
            })
            const json = await res.json()
            setItems(json)
        } catch (err) {
            console.log(err)
            setError(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchItems()
    }, [])



    const totalItems = showDetails ? Object.values(selectedVals).reduce((acc, curr) => {
        return acc + curr.length
    }, 0) : items.reduce((acc, curr) => acc + curr.length, 0)

    return (
        <div className='flex flex-col flex-1'>
            <button onClick={() => setShowDetails(!showDetails)} className='border-violet-700 rounded text-violet-700 hover:text-white hover:bg-violet-700 border border-solid border-violet-700 w-fit px-8 py-1'>{showDetails ? 'Hide' : 'Show'} Details</button>
            {!isLoading && <DetailsTable showDetails={showDetails} setFunctions={setFunctions} items={items} selectedVals={selectedVals} />}
            {totalItems > 0 && <div className='flex flex-1 items-end gap-4'>
                {['restore', 'delete'].map((type => {
                    return <button onClick={() => handleSubmit(type)} data-cy={'trash-' + type}
                        className={'flex-1 capitalize border border-solid duration-300 py-2 ' + (type === 'restore' ? 'text-green-500 border-green-500 hover:bg-green-400 hover:text-white' : 'text-rose-500 border-rose-500 hover:bg-rose-400 hover:text-white')}
                        key={type}>{type === 'delete' ? 'Permanently' : ''} {type} {totalItems} item{totalItems > 1 && 's'}</button>
                }))}
            </div>}
        </div>
    )
}
