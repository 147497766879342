import LinkWithBadge from "./LinkWithBadge"
import * as styles from './menu.module.css'


const LinksList = ({ darkTheme, links, heading, handleSelection, disableLink }) => {
  return (
    <ul
      className="flex sm:flex-col sm:w-full"
      onClick={(e) => {
        if(heading && (e.target.localName !== 'h2' && e.target.localName !== 'li')) {
          handleSelection(heading)
        }
    }}
    >
      {heading &&
        <li key={heading} className={`${styles.listItem} px-3 sm:block hidden border-t-[1px] border-[var(--ztn-purple-grey)] border-solid`}>
          <h2 className={`text-base normal-case my-2.5 text-left`}>{heading}</h2>
        </li>
      }
      {links.map(link =>
        <li key={link.title} className={`${styles.listItem} flex items-center hover:bg-purple-200 ${disableLink && 'cursor-not-allowed'}`}>
          <LinkWithBadge href={link.link} disableLink={disableLink}>
              {link.icon && <link.icon />}
            <p className="text-[#353239] font-normal">{link.title}</p>
          </LinkWithBadge>
        </li>
      )}
    </ul>
  );
}

export default LinksList;
