import { createContext } from "react"
import useLocalStorage from "../utils/useLocalStorage";

const Theme = createContext({ isDark: false, toggle: () => { } });

export const DarkThemeProvider = ({ children }) => {
  const [darkTheme, setDarkTheme, readyToRender] = useLocalStorage('darkTheme', false);
  const toggleTheme = () => setDarkTheme(!darkTheme);

  return (
    <Theme.Provider value={{darkTheme, toggleTheme}}>
      {readyToRender && children}
    </Theme.Provider>
  );
};

export default Theme
