import React, { useState } from "react";
import { useRouter } from "next/router";
import { useMutation } from "react-query";
import axios from "axios";
import styles from "./ForgotPassword.module.css";
import BaseCard from "../ui/BaseCard";
import BaseButton from "../ui/BaseButton";
import validator from "validator";
import AccountLayout from "../Layouts/AccountLayout";
import EmailSent from "./EmailSent";
import Alert from "../ui/Alert";

const ForgotPassword = () => {
  const router = useRouter();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [form, setForm] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!formErrors[field]) {
      setFormErrors({
        ...formErrors,
        [field]: "",
      });
    }
  };

  const validateForm = () => {
    const email = form.email || "";
    const errors = {};

    if (validator.isEmpty(email))
      errors.email = "Please enter an email address";
    else if (!validator.isEmail(email))
      errors.email = "Please enter a valid email address";

    return errors;
  };

  const mutation = useMutation(
    (event) => {
      event.preventDefault();

      const errors = validateForm();

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        throw new Error(
          "This form has errors. Please fix them before proceeding."
        );
      }

      return axios.post(`${process.env.NEXT_PUBLIC_API_URL}/reset`, {
        username: form.email,
      });
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        //ask about this
        setIsEmailSent(true);
      },
    }
  );

  return (
    <AccountLayout>
      {isEmailSent ? (
        <EmailSent email={form.email} />
      ) : (
        <BaseCard title="Forgot Password" className={styles.card}>
          <Alert
            className="mb-4"
            show={mutation.isError && !mutation.isLoading}
            variant="error"
          >
            {mutation.error?.message}
          </Alert>
          <div className={styles.instruction}>
            Please enter your account email to reset your password.
          </div>
          <form role="form" onSubmit={mutation.mutate}>
            <div className="mb-10">
              <label className="block text-gray-500  mb-3" htmlFor="username">
                Email
              </label>
              <input
                className="border border-solid border-gray-300 focus:border-gray-300 focus:ring-0 bg-transparent rounded w-full py-3 px-6 text-gray-700"
                id="username"
                type="text"
                placeholder="Enter email address"
                value={form.email}
                onChange={(e) => {
                  setField("email", e.target.value);
                }}
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}
            </div>

            <BaseButton width="100" type="submit" disabled={mutation.isLoading}>
              Reset Password
            </BaseButton>
          </form>
          <div className={`${styles["actions__row1"]} text-md`}>
            <div>
              <button
                onClick={() => router.push("/login")}
                className={styles.link}
              >
                <img src="/arrow-left.svg" className={styles.link__arrow} />
                <span className={styles.link__text}>Back to Login</span>
              </button>
            </div>
          </div>
        </BaseCard>
      )}
    </AccountLayout>
  );
};

export default ForgotPassword;
