import React from 'react'
import ZustandUploader from "./ZustandUploader"

const Main = ({ children, className }) => {
  const componentName = children[0].type.name;
  const wideComponentArr = ['Projects', 'OrganizationPage', 'CreateOrganizationPage', 'JoinOrganizationPage', 'BillingPage']

  return (
    <main className={`${wideComponentArr.includes(componentName) ? 'px-10' : 'sm:pl-52 pr-4'} relative  flex-1 w-full max-w-[1764px] mx-auto transition-none`}> {/* min-[1900px]:ml-[109.5px] */}
      {children}
      <ZustandUploader />
    </main>
  )
}

export default Main
