import React from "react";

export default function AccountLayout(props) {
	return (
			<div className="flex min-h-screen">
				<div className="w-0 sm:w-24 min-h-screen bg-gradient-to-b from-red-800 to-purple-900"></div>
			<main className="flex-1">
						<img src="/zetane-protector-w-text.png" width="200px" className='ml-10 mt-10' />
					{props.children}
				</main>
			</div>
	);
}
