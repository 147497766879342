import { useState } from "react"
import { createContainer } from 'react-tracked';

const userInitialState = {};

const useUserState = () => useState(userInitialState);

export const createProjectObject = (selectedOrg, projectSlug) => {
	if (selectedOrg && selectedOrg.projects) {
		const projects = selectedOrg.projects;

		const selectedProject = projects.find(project => projectSlug == project.name)
		if (selectedProject) {
			return selectedProject
		}
	}

	return null;
}

export const getDefaultOrg = (user) => {
	if (user && user.organizations) {
		return user.organizations[0];
	}
	return null;
}

export const getOrg = (user, orgId) => {
	if (user && user.organizations) {
		const orgs = user.organizations;
		const selectedOrg = orgs.find(org => orgId == org.id)
		if (selectedOrg) {
			return selectedOrg;
		}
	}
	return null;
}

export const getOrgName = (user, orgId) => {
	if (user && user.organizations) {
		const selectedOrg = getOrg(user, orgId);
		if (selectedOrg) {
			const orgName = selectedOrg.name;
			return orgName;
		}
	}
	return null;
}

export const getSlug = (textOrObject) => {
	return textOrObject;
	//return slugify(textOrObject);
}

export const getOrgSlug = (user, orgId) => {
	const orgName = getOrgName(user, orgId);
	if (orgName) {
		const orgSlug = getSlug(orgName);
		return orgSlug;
	}
	return null;
}

export const updateOrgProjects = (user, orgId, newProjects, setUserState) => {
	if (user && user.organizations) {
		const orgs = user.organizations
		const newOrgs = orgs.map((org) => {
			if (org.id == orgId) {
				return {
					id: org.id,
					name: org.name,
					projects: newProjects
				}
			} else {
				return org;
			}
		})

		setUserState((prev) => {
			return {
				...prev,
				newOrgs
			}
		})
	}
}

export const { Provider: UserStateProvider, useTracked: useTrackedUser } = createContainer(useUserState);
