import styles from "./TermsAndConditions.module.css";
//import Link from "next/link";
import { useRouter } from "next/router";
import AccountLayout from "../Layouts/AccountLayout";

export default function TermsAndConditions() {
	const router = useRouter();

	return (
		<AccountLayout>
		<div className={styles.container}>
			<button onClick={router.back} className={styles.link}>
				<img src="/arrow-left.svg" className={styles.link__arrow}/>
				<span className={styles.link__text}>Back</span>
			</button>
			<h1 className={styles.title}>Terms & Conditions</h1>
			<div className={styles.terms}>
				<p>These terms govern your access and use of our 3D modelling and simulation platform (the &#34;Platform&#34;) and its associated services (together, the &#34;Services&#34;) and related services (together, the &#34;Services&#34;). These terms apply to all users of our Services, and are effective as soon as you agree to these terms by clicking &#34;I agree&#34;, or as soon as you use our Services. Please read them carefully. If you do not agree with these terms, please refrain from using our Services.</p>

				<p>This End User License Agreement (&#34;EULA&#34;) is between you and Zetane Systems Inc, with a registered address at 1732 Blueberry Forest, Saint-Lazare, QC J7T 2K1, Canada (&#34;Zetane&#34;; &#34;we&#34;; &#34;us&#34;).</p>

				<p>If you have any questions on this EULA, you can reach us at legal@zetane.com.</p>

				<p>In this EULA, the verb &#34;use&#34; implies accessing, installing, downloading, copying or otherwise benefiting from using our Services or from the object of the licences set forth in this EULA.</p>

				<h3>1.	DEFINITIONS</h3>

				<ul>
					<li>*	&#34;Confidential Information&#34; means any and all information of Zetane which has or will come into your possession concerning our business, properties, affairs or finances, including proprietary information and trade secrets. Confidential Information is indicated as confidential, or it is clear at the time of disclosure that the information ought to be handled as confidential information.</li>
					<li>*	&#34;Commercial Licence&#34; means a licence for a group of End Users acquired by Customer, who is responsible for allocating and managing the licences to End User. Commercial Licence may be used for commercial purposes, or for other purposes such as educational purposes.</li>
					<li>*	&#34;Individual Licence&#34; means a licence to use the Services for personal and non-commercial purposes.</li>
					<li>*	&#34;Intellectual Property&#34; means (a) any and all proprietary rights provided under patent law, copyright law (registered and unregistered copyrights and unpublished work of authorship), trademark law, design patents or industrial design law, semiconductor chip law, or any other statutory provision or common law principle applicable to the protection of intangible proprietary information or rights, including trade secret law, which may provide a right in either idea, formula, algorithm, concept, invention, or know-how generally, or the expression or use of such ideas, formulae, algorithms, concepts, inventors or know-how, and any and all applications, registrations, licences, sub-licences, continuation, reissues, extensions, franchises, agreements or any other evidence of a right in any of the foregoing.</li>
					<li>*	&#34;Open Source Software&#34; means any software licensed under Open Source Licence terms.</li>
					<li>*	&#34;Open Source Licence Terms&#34; means the licensing and/or distribution models commonly known as &#34;open source software&#34; or &#34;free software&#34; or any other licensing and/or distribution models pursuant to which software is made generally available to the public in source code form under terms that permit modification and redistribution of such software. By way of example only and without limitation, Open Source Licence Terms include any versions of the following agreements, licences or distribution models: (1) the GNU General Public Licence (GPL); (2) Lesser/Library GPL (LGPL); (3) the Common Development and Distribution Licence (CDDL); (4) the Artistic Licence (including without limitation PERL); (5) the Netscape Public Licence; (6) the Sun Community Source Licence (SCSL) or the Sun Industry Standards License (SISL); (7) the Apache Licence; (8) the Common Public Licence; (9) the Affero GPL (AGPL), (10) the Berkeley Software Distribution (BSD), and (11) the MIT Licence (MIT).</li>
					<li>*	&#34;Professional Licence&#34; means a licence to use the Service for a sole professional and may be used commercial purposes, or other purposes such as educative.</li>
				</ul>

				<h3>2.	RELATIONSHIP BETWEEN YOU, CUSTOMER AND US</h3>

				<p>We provide services to customer who have entered into an agreement with us, each a &#34;Customer&#34;. We make the following licences available to Customers:</p>

				<ul>
					<li>*	Individual Licenses</li>
					<li>*	Professional Licenses</li>
					<li>*	Commercial Licenses</li>
				</ul>

				<p>Customer with Commercial Licenses (&#34;Commercial Customer&#34;) can assign licenses to third parties, each a &#34;End User&#34;. You acknowledge and agree that Commercial Customers may (1) terminate, suspend or allocate access to End Users without our intervention and (2) control some of the functionalities of the Services for End Users. You agree that it is solely Commercial Customer’s responsibility to (a) inform you of its policies and practices; (b) obtain any rights, permissions or consents required for us to perform the Services and (c) resolve any dispute with you regarding your use and access of the Services.</p>

				<p>Each Customer is a third-party beneficiary to this EULA and can enforce this EULA as required to protect their liability against us.</p>

				<p>Customer that have for End Users may terminate, suspend or allocate such licenses without consulting with us. Such Customer may also control some functionalities associated with your access through administrative accounts.</p>

				<h3>3.	REGISTRATION</h3>

				<p>Our Services are not intended for anyone under the age of 16 years old. If you are under the age of 16 years old, you may use our Services by providing us with a written notice of parental consent.</p>

				<p>When using our Services, you will be required to create an online identifier and a password to access your account with us (the &#34;Registration Data&#34;). You agree that you are responsible for maintaining the confidentiality of your Registration Data, and in particular, of your password. If you become aware of any unauthorized access or use of your Registration Data, you agree to notify us without undue delay at info@zetane.com.</p>

				<h3>4.	ACCEPTABLE USE</h3>

				<p>You agree to use the Services only for lawful purposes and follow the following rules when using the Services. You represent and warrant that you will not use of the Services in any manner:</p>

				<ul>
					<li>a)	that is prohibited by law or regulation or our policies made available to you;</li>
					<li>b)	that will disrupt third parties’ use or enjoyment of the Services, including if this use results in automated, constant and repeated requests for data other than as permitted and has a negative effect on our systems or network, including abnormal usage that overloads servers or causes portions of our network to be blocked (e.g. denial-of-services and distributed-denial-of-services attacks);</li>
					<li>c)	to create, transmit, distribute or store material that violates Intellectual Property, privacy, publicity or other personal rights of individuals, export control, or that can otherwise be threatening, abusive, hateful or constitutes or encourages conduct that would be considered a fraud, a criminal offence or likely to give rise to civil liability;</li>
					<li>d)	that results in (i) the sharing of identifiers and passwords with other individuals or entities, including through any time-sharing service, service bureau, network or by any other means;</li>
					<li>e)	that involves using any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, programs, algorithms, or methodology to access, acquire, copy or monitor the Services or any portion of the Services;</li>
					<li>f)	that involves decompiling, disassembling, or otherwise reverse engineering or attempting to reconstruct or discover any source code or ideas or algorithms of any of the Services underlying technology by any means whatsoever;</li>
					<li>g)	that involves penetrating our security, including, without limitation:</li>
					<li>a.	by posting or transmitting any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features;</li>
					<li>b.	by interfering with the proper working of the Services;</li>
					<li>c.	by attempting to hack any security requirements or processes in the use of the Services;</li>
					<li>d.	by attempting to access any part of the Services (or any of their related systems, networks, servers or other equipment) which Customer is not authorized to access;</li>
					<li>e.	by attempting to disrupt in any manner the operation of the Services, its servers or network;</li>
					<li>f.	by disobeying any requirements, procedures, policies or regulations of your network connected to the Services;</li>
					<li>g.	by manipulating identifiers to disguise the origin of any content transmitted or uploaded on to the Services, or the source of any content;</li>
					<li>h.	by modifying or altering the Services in any unauthorized manner.</li>
				</ul>

				<p>(Collectively, the &#34;Abuses&#34;).</p>

				<p>You agree and acknowledge that an indirect or attempted violation of this Section shall be considered an Abuse. If we discover that you have committed an Abuse or is planning to commit an Abuse or otherwise encourage or facilitate the commission of an Abuse, we may suspend part your access to the Services without any prior notice.</p>

				<h3>5.	GRANT OF LICENSES</h3>

				<p>Subject to your respect of this EULA, we grant you, for the Term, a non-exclusive, non-sublicensable, non-transferable, limited and revocable right and license to access and use our Services (for greater precision, including to install and download the Platform on your device). All rights not expressly granted hereunder are reserved by us. You may not sell, transfer, sublicense, public, disclose or otherwise make available the Services to any third party without our prior written consent.</p>

				<h3>6.	INTELLECTUAL PROPERTY</h3>

				<p>All rights not granted are reserved by us. We are the sole and exclusive owner of our pre-existing Intellectual Property. We do not grant you any rights, implied or otherwise, to our Intellectual Property. You may make suggestions, enhancement requests, recommendations or other feedbacks (&#34;Feedbacks&#34;). We will be the sole owner of such Feedback as long as they relate to the Services and you hereby assign to us, without limitation of any kind, all rights, titles and interests therein.</p>

				<h3>7.	OPEN SOURCE SOFTWARE</h3>

				<p>The Services may contain Open Source Software. The terms and conditions governing your use of such Open Source Software shall be in accordance with the Open Source Licence Terms associated with such Open Source Software.</p>

				<h3>8.	THIRD PARTY BENEFICIARY</h3>

				<p>The Software may contain licensed software, data and material from third party (&#34;Third Party Material&#34;). Licensors of Third-Party Material included in the Software are third party beneficiary of these Terms of Service and the EULA.</p>

				<p>Each Customer is also a third-party beneficiary of this EULA.</p>

				<h3>9.	DISCLAIMERS</h3>

				<p>You are solely responsible for the content, accuracy, completeness and lawfulness of the data that you upload, download, install, or otherwise process through our Services. We take no responsibility for the content, accuracy, completeness and lawfulness of such data. The Services are provided on an &#34;as is&#34; basis. To the maximum extent permitted by law, we make no other representations, conditions, warranties or guarantees, express or implied, regarding the accuracy, reliability or completeness of the Services. We expressly disclaim any and all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. We do not warrant that the Services will be uninterrupted or error free. We do not make any warranty as to the results that may be obtained from the use of the Services. You hereby waive your rights in any of the foregoing warranties, representations or conditions, whether express or implied.</p>

				<p>We do not operate the networks of, or have any control over the operations of, the wireless or other communications service providers through which you may access the Services. Accordingly (i) we disclaim all responsibility and liability for or relating to your use of any such providers to access the Services and (ii) we cannot guarantee the privacy or security of wireless data transmissions. You should consult your Internet service provider about their privacy and security practices.</p>

				<p>No oral advices or written or electronically delivered information given by us, our affiliates, our officers, directors, employees, agents, providers, merchants, sponsors, licensors or the like shall create any warranty.</p>

				<h3>10.	CONFIDENTIAL INFORMATION</h3>

				<p>You acknowledge that you may be exposed to or acquire information in connection with the Services that classifies as Confidential Information. For the purpose of these Terms of Services, Confidential Information shall not include:</p>

				<ul>
					<li>*	Information which is generally known or available publicly, including information which becomes generally known through no fault or breach of the Receiving Party;</li>
					<li>*	Information which was known by the Receiving Party prior to receive the information from the Disclosing Party;</li>
					<li>*	Information which is independently developed by the Receiving Party without the direct or indirect use of Confidential Information;</li>
					<li>*	Information which is lawfully obtained from a third party without violation of a confidentiality obligation of the Disclosing Party.</li>
				</ul>

				<p>You agree to keep our Confidential Information as confidential, subject to applicable laws, and apply no less than reasonable care to prevent unauthorized access, use or disclose of our Confidential Information. You shall not use our Confidential Information to develop competitive services or allow, directly or indirectly, third parties to develop such competitive services.</p>

				<h3>11.	INDEMNITY</h3>

				<p>You agree to defend us and hold us harmless (including our affiliates, subsidiaries, licensors, suppliers, service providers, contractors, and agents, as well as their respective employees, directors, officers, contractors and agents) against any and all claims, penalties, fine, cost, expenses (including reasonable attorney’s fees), actions, damages, losses or liabilities directly or indirectly arising out of, related to, in connection with or resulting from:</p>

				<ul>
					<li>*	A breach of this EULA;</li>
					<li>*	A breach of applicable laws;</li>
					<li>*	Your gross negligence, fraudulent behavior and willful acts.</li>
				</ul>

				<h3>12.	LIMITATION OF LIABILITY</h3>

				<p>To the maximum extent permitted by law, neither party will be liable to the other party for any incidental, consequential, or exemplary damages, in connection with the performance of the Services, even if the party is aware of the possibility of occurrence of such damages.</p>

				<p>To the maximum extent permitted by law, our total liability to you for any damages arising out or in connection with this EULA, whether arising by statute, contract tort or otherwise, will not exceed one hundred Canadian dollars (100$).</p>

				<h3>13.	EXPORT CONTROL</h3>

				<p>You may not export, re-export, sell, transfer, divert or otherwise dispose of our Services in any destination which is restricted or prohibited by Canada, the country in which you are located or other applicable jurisdictions. You agree not to use the Services to disturb international peace or for other purposes which are restricted by international treaties and applicable export control laws.</p>

				<h3>14.	GOVERNING LAWS AND JURISDICTIONS</h3>

				<p>These Terms of Service and all transactions hereunder shall be governed by and construed in accordance with the laws of Ontario, Canada, without giving effect to any choice or conflict of law provision or rule (whether in Ontario or any other jurisdiction) that would cause the application of laws of any jurisdiction other than those of Ontario, Canada.</p>

				<p>Any legal suit, action or proceeding, arising out of these Terms of Service or any transactions hereunder shall be instituted exclusively in Toronto, Ontario, Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action or proceeding.</p>

				<h3>15.	MISCELLEANOUS</h3>

				<p>If any provision of this EULA is in violation of any governmental statute or regulation, or is illegal for any reason, said provision shall be self-deleting without affecting the validity of the remaining provisions.</p>

				<p>The waiver of a breach of any provision of this EULA will not operate or be interpreted as a waiver for any other or subsequent breach.</p>

				<p>You may not use our name or any logo, trademark, service mark, business name, trade name, domain name or social media account name or handle owned or licensed by us or generally speaking, our brand features, whether registered or not, and including any good will associated therewith, in any manner without our prior written consent.</p>

				<p>We shall not be liable for delays in or failure of performance hereunder due to causes beyond its reasonable control, including, but not limited to, acts of God or public enemy, acts of government in either its sovereign or contractual capacity, flood, earthquake or other natural disaster, strike or other labor disputes, acts of war, acts of civil disobedience, denial-of-services and distributed-denial-of-services, ransomware and other cyber-attacks that are not caused or facilitated by our gross negligence.</p>

				<p>We may assign this EULA, in whole or in part, at any time with or without notice to you. You may not assign this EULA, or part of it, to any other person without our prior written approval. Any attempt by you to do so is void. You may not transfer to anyone else, either temporarily or permanently, any rights to use the Services or any part of the Services.</p>
			</div>
		</div>
		</AccountLayout>
	);
}
