import React from 'react'
import ReactDom from 'react-dom'
import { useHelpState } from './stores/helpModal'

export default function HelpModal(props) {

    const closeHelpHandler = useHelpState(state => state.closeHelpHandler)
    const openModal = useHelpState(state => state.showHelpModal)
    const link = useHelpState(state => state.link)
    const errorMessage = useHelpState(state => state.errorMessage)

    if (!openModal) { return null }

    let body = (
        <div className='fixed bottom-0 right-0 w-[250px] md:w-72 m-4 border bg-white border-violet-700 border-solid shadow-lg  overflow-hidden rounded-t-lg rounded-bl-lg flex flex-col gap-2'>
            <div className='flex items-center justify-between px-4 py-2 bg-purple-700 text-white '>
                <h4>Zetane Assistant</h4>
                <i onClick={closeHelpHandler} className="fa-solid fa-xmark cursor-pointer"></i>
            </div>
            <div className='flex flex-col gap-2 p-4 text-sm overflow-scroll flex-1'>
                {errorMessage ? <p>You encountered an error while you were {errorMessage}.</p> : <p>You encountered an error.</p>}
                <p>Read more about how you can resolve this error in our documentation!</p>
            </div>
            <a href={link || 'https://docs.zetane.com/'} target='_blank' rel="noreferrer" className='ml-auto text-purple-700 py-1 px-2 rounded-lg border border-solid border-slate-200 duration-200 cursor-pointer hover:border-purple-200 mb-4 mr-4 mt-2'>Read more</a>
        </div>
    )
    return ReactDom.createPortal(body, document.getElementById('helpPortal'))
}
