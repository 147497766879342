import { useContext, useState, useEffect, useRef } from "react";
import IconProfile from "../ztn/icons/profile.svg";
import Auth from "../ztn/contexts/Auth.js";
import { useTrackedUser } from "../ztn/contexts/User";
import { useRouter } from "next/router";
import Modal from "components/ztn/Modal";
import RestoreDelete from "components/RestoreDelete";

const EmbeddedUser = (props) => {
	const [user, setUserState] = useTrackedUser();
	const [showList, setShowList] = useState(false);
	const [showAPIKEY, setShowAPIKEY] = useState(false)
	const [showKEY, setShowKEY] = useState(false)
	const [showTrashDialog, setShowTrashDialog] = useState(false);
	const [APIKEY, setAPIKEY] = useState('')
	const { toggleLogin } = useContext(Auth);
	const userLink = `/users/${user.username}`;
	const router = useRouter();
	const modalRef = useRef();
	const logout = () => {
		toggleLogin(undefined);
		router.push("/");
	};
	const { getAuthHeaders } = useContext(Auth);
	const authHeaders = getAuthHeaders();
	const { org } = router.query;

	useEffect(() => {
		if (!showAPIKEY) {
			return
		}
		async function fetchAPIKEY() {
			const fetchUrl = `${process.env.NEXT_PUBLIC_API_URL}/api/token`;
			const res = await fetch(fetchUrl, {
				method: 'GET',
				headers: getAuthHeaders().headers,
			})
			const { token } = await res.json()
			setAPIKEY(token)
		}
		fetchAPIKEY()
	}, [showAPIKEY])

	useEffect(() => {
		const handleEsc = (event) => {
			if (event.keyCode === 27) {
				setShowList(false);
			}
		};
		window.addEventListener("keydown", handleEsc);
		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", closeModal);
		return () => document.removeEventListener("mousedown", closeModal);
	}, []);

	if (!user) {
		return (
			<span className="cursorPointer hoverDarken p-1 m-2 roundedSmall">
				<IconProfile></IconProfile>
			</span>
		);
	}

	function closeModal(e) {
		e.stopPropagation();
		if (modalRef.current) {
			if (
				modalRef.current !== e.target &&
				!modalRef.current.contains(e.target)
			) {
				setShowList(false);
			}
		}
	}

	return (
		<div className="relative">
			<div data-cy='embedded-user-icon'
				className={
					"cursor-pointer hover:text-purple-700 text-2xl pl-1" +
					(showList ? " text-purple-700" : "")
				}
				onClick={() => {
					setShowList(!showList)
				}}
			>
				<IconProfile></IconProfile>
			</div>
			<Modal title={'Get API KEY'} open={showAPIKEY} onClose={() => setShowAPIKEY(false)} miniModal={true}>
				<div className="grid place-items-center w-full h-full">
					<div className="flex flex-col gap-4 ">
						<h4 className="text-base">This is a private key and should not be shared or shown to any other users.</h4>
						<div className="rounded border border-solid border-violet-700 flex items-center gap-4  overflow-hidden w-60 mx-auto">
							<p className={"px-4 py-2 overflow-x-scroll whitespace-nowrap text-xs flex-1 text-center " + (showKEY ? ' ' : ' select-none')}>{showKEY ? APIKEY : '●●●●●●●●●●●●'}</p>
							<div onClick={() => setShowKEY(!showKEY)} className={'duration-300 text-white cursor-pointer text-xl px-4 py-2 ' + (showKEY ? ' text-white bg-violet-700' : "bg-violet-300 text-white")}><i className="fa-regular fa-eye"></i></div>
						</div>
						<div className="border cursor-pointer duration-200 hover:opacity-70 border-solid rounded border-slate-400 px-4 py-2 text-slate-600 flex items-center justify-between gap-4 w-60 mx-auto" onClick={() => { navigator.clipboard.writeText(APIKEY) }}
						>
							<p>Copy to Clipboard</p>
							<i className="fa-regular fa-copy"></i>
						</div>
					</div>
				</div>
			</Modal>
			{showList && (
				<div
					ref={modalRef}
					className="absolute bottom-0 right-0 translate-y-full border border-solid border-purple-300 p-2 shadow text-base text-violet-600 bg-purple-100 min-w-max"
				>
					<div className="border-b border-solid border-white mb-1 p-1">
						{user.username}
					</div>
					{/* <a className='p-1' href={userLink} onClick={() => setShowList(false)}>Edit Profile</a> */}
					<div
						className="cursor-pointer hover:opacity-50 hover:transform-opacity p-1"
						onClick={() => {
							setShowAPIKEY(true)
							setShowList(false);
						}}
					>
						Get API KEY
					</div>
					<a href="https://docs.zetane.com/docs/protector/"
						className="cursor-pointer hover:opacity-50 hover:transform-opacity p-1"
					>
						Documentation
					</a>
					<div
						className="cursor-pointer hover:opacity-50 hover:transform-opacity p-1"
						onClick={() => {
							router.push("/billing");
							setShowList(false);
						}}
					>
						Billing
					</div>
					<div data-cy='trash-display'
						className="cursor-pointer hover:opacity-50 hover:transform-opacity p-1 pt-0"
						onClick={() => {
							setShowList(false);
							setShowTrashDialog(true);
						}}
					>
						Recycle Bin
					</div>
					<div
						className="cursor-pointer hover:opacity-50 hover:transform-opacity p-1 pt-0"
						onClick={() => {
							setShowList(false);
							logout();
						}}
					>
						Logout
					</div>
				</div>
			)}

			<Modal open={showTrashDialog} onClose={() => setShowTrashDialog(false)} miniModal={true}>
				<RestoreDelete selectedOrg={props.selectedOrg} user={user} />
			</Modal>
		</div>
	);
};

export default EmbeddedUser;
