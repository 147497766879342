import create from 'zustand'
import produce from 'immer'
import omit from 'lodash-es/omit'
import { v4 as uuidv4 } from 'uuid';

export const useUploadState = create((set, get) => ({
  uploadQueue: {},
  processUpload: async (files, meta) => {
    const uuid = uuidv4()
    set(produce(state => { state.uploadQueue[uuid] = {name: uuid, upload_status: {status: 'Pending', statusValue: 0}}}))
    return uuid
  },
  createUpload: () => {
    const uuid = uuidv4()
    set(produce(state => { state.uploadQueue[uuid] = {name: uuid, upload_status: {status: 'Preparing Upload', statusValue: 0}}}))
    return uuid
  },
  startUpload: (data, oldId) => {
    const newStatus = { status: "Uploading", statusValue: 0 }
    data.upload_status = newStatus;
    set(produce(state => { state.uploadQueue[data.id] = data }))
    set(produce(state => { state.uploadQueue = omit(state.uploadQueue, [oldId])}))
  },
  chunkUpdate: (id, chunk) => {
    const row = get().uploadQueue[id];
    if (row) {
      const newStatus = { status: "Uploading", statusValue: row.upload_status.statusValue + chunk }
      set(produce(state => { state.uploadQueue[id].upload_status = newStatus }))
    }
  },
  completeUploadInQueue: (id) => {
    const row = get().uploadQueue[id];
    const newStatus = { status: "Ready", statusValue: 100 }
    if (row) {
      set(produce(state => { state.uploadQueue[id].upload_status = newStatus }))
    }
  },
  deleteFromQueue: (id) => {
    set(produce(state => { state.uploadQueue = omit(state.uploadQueue, [id])}))
  },
  updateStatus: (id, text, value, options) => {
    const newStatus = { status: text, statusValue: value, ...options }
    set(produce(state => { state.uploadQueue[id].upload_status = newStatus }))
  }
}))

export const useTrackFiles = create((set, get) => ({
  folder: {},
  setFolderInstance: (folderUUID, totalFiles) => {
    set(produce(state => {
      state.folder[folderUUID] = {
        totalFiles: totalFiles,
        filesUploaded: 0,
        folderData: {name: null, id: null},
        fileUploadProgress: {},
        folderSize: 0
    }}))
  },
  getFolderData: (folderUUID) => {
    if (!folderUUID) { return false; }
    const {totalFiles, filesUploaded, folderData} = get().folder[folderUUID];
    return (totalFiles === filesUploaded) ? folderData : false;
  },
  getFileProgress: (folderUUID) => {
    const {fileUploadProgress, folderSize} = get().folder[folderUUID];
    const bytesUploaded = Object.values(fileUploadProgress)
    return (bytesUploaded.length && folderSize) ? Math.round((bytesUploaded.reduce((total, current) => total + current) / folderSize) * 100) : 0
  },
  updateFolder: ({updateType, ...content}) => {

    if (updateType === 'folderSize') {
      set(produce(state => {state.folder[content.folderUUID].folderSize = content.size; }))
    } else if (updateType === 'fileProgress') {
      set(produce(state => { state.folder[content.folderUUID].fileUploadProgress[content.fileUUID] = content.progress; }))
    } else if (updateType === 'fileUploaded') {
      set(produce(state => { state.folder[content.folderUUID].filesUploaded++; }))
    } else if (updateType === 'folderData') {
      const newFolderData = { name: content.name, id: content.id }
      set(produce(state => { state.folder[content.folderUUID].folderData = newFolderData
      }))
    }

  },
  deleteInstance: (folderUUID) => { set(produce(state => { delete state.folder[folderUUID]; }))
  }
}))
