import style from './ErrorPage.module.scss';
import Link from 'next/link';
import { useState } from 'react';

export default function ErrorPage(props) {
  const [showDetails, setShowDetails] = useState(false);

  const {
    title = 'An error has occured',
    imagePath = '/exclamation.svg',
    details,
    children = 'Oops... something went wrong'
  } = props;

  const toggleShowDetails = () => setShowDetails(!showDetails);

  return (
    <section className={style.container}>
      <header className={style.header}>
        <img src={imagePath} />
        <h1>{title}</h1>
      </header>
      <main className={style.message}>
        {children}
      </main>
      <footer>
        <div className={style.actions}>
          {details &&
            <div className={style['view-details']} onClick={toggleShowDetails}>
              <span>View Details</span>
              <img className={showDetails ? style.rotate180 : null}src="/angle-small-up.svg" />
            </div>
          }
          <Link href="/">
            <a className={style.link}>Back to safety</a>
          </Link>
        </div>
        {showDetails &&
          <p className={style.details}>
            {details}
          </p>
        }
      </footer>
    </section>
  );
}
