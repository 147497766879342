import mixpanel from 'mixpanel-browser';

const isProd = process.env.NODE_ENV === 'production';
const isStaging = process.env.IS_STAGING === 'true';

let token
if (isStaging) {
  token = '89b760362bee07af3fc37e99b3ce5408';
} else if (isProd) {
  token = '5855fbf5447a76373a321e524e879ede';
} else { // Environment is development
  token = '211307a7ccb83c2d6e206b283ceb20a9';
}

mixpanel.init(token, {
  // HTTP method for tracking requests
  api_method: 'POST',

  // transport for sending requests ('XHR' or 'sendBeacon')
  // NB: sendBeacon should only be used for scenarios such as
  // page unload where a &quot;best-effort&quot; attempt to send is
  // acceptable; the sendBeacon API does not support callbacks
  // or any way to know the result of the request. Mixpanel
  // tracking via sendBeacon will not support any event-
  // batching or retry mechanisms.
  api_transport: 'XHR',

  // turn on request-batching/queueing/retry
  batch_requests: false,

  // maximum number of events/updates to send in a single
  // network request
  batch_size: 50,

  // milliseconds to wait between sending batch requests
  batch_flush_interval_ms: 5000,

  // milliseconds to wait for network responses to batch requests
  // before they are considered timed-out and retried
  batch_request_timeout_ms: 90000,

  // override value for cookie domain, only useful for ensuring
  // correct cross-subdomain cookies on unusual domains like
  // subdomain.mainsite.avocat.fr; NB this cannot be used to
  // set cookies on a different domain than the current origin
  cookie_domain: '',

  // super properties cookie expiration (in days)
  cookie_expiration: 365,

  // if true, cookie will be set with SameSite=None; Secure
  // this is only useful in special situations, like embedded
  // 3rd-party iframes that set up a Mixpanel instance
  cross_site_cookie: false,

  // super properties span subdomains
  cross_subdomain_cookie: true,

  // debug mode
  debug: !isProd || isStaging,

  // if this is true, the mixpanel cookie or localStorage entry
  // will be deleted, and no user persistence will take place
  disable_persistence: false,

  // if this is true, Mixpanel will automatically determine
  // City, Region and Country data using the IP address of
  //the client
  ip: true,

  // opt users out of tracking by this Mixpanel instance by default
  opt_out_tracking_by_default: false,

  // opt users out of browser data storage by this Mixpanel instance by default
  opt_out_persistence_by_default: false,

  // persistence mechanism used by opt-in/opt-out methods - cookie
  // or localStorage - falls back to cookie if localStorage is unavailable
  opt_out_tracking_persistence_type: 'localStorage',

  // customize the name of cookie/localStorage set by opt-in/opt-out methods
  opt_out_tracking_cookie_prefix: null,

  // type of persistent store for super properties (cookie/
  // localStorage) if set to 'localStorage', any existing
  // mixpanel cookie value with the same persistence_name
  // will be transferred to localStorage and deleted
  persistence: 'cookie',

  // name for super properties persistent store
  persistence_name: '',

  // names of properties/superproperties which should never
  // be sent with track() calls
  property_blacklist: [],

  // if this is true, mixpanel cookies will be marked as
  // secure, meaning they will only be transmitted over https
  secure_cookie: false,

  // the amount of time track_links will
  // wait for Mixpanel's servers to respond
  track_links_timeout: 300,

  // If true, will send the `mp_page_view` event back to the
  // Mixpanel servers. Set this to false since Mixpanel no longer
  // ingests the `mp_page_view` event. Reference:
  // https://help.mixpanel.com/hc/en-us/articles/115004568086-The-mp-page-view-Event
  track_pageview: false,

  // if you set upgrade to be true, the library will check for
  // a cookie from our old js library and import super
  // properties from it, then the old cookie is deleted
  // The upgrade config option only works in the initialization,
  // so make sure you set it when you create the library.
  upgrade: false,

  // extra HTTP request headers to set for each API request, in
  // the format {'Header-Name': value}
  xhr_headers: {},

  // protocol for fetching in-app message resources, e.g.
  // 'https://' or 'http://'; defaults to '//' (which defers to the
  // current page's protocol)
  inapp_protocol: '//',

  // whether to open in-app message link in new tab/window
  inapp_link_new_window: false,

  // whether to ignore or respect the web browser's Do Not Track setting
  ignore_dnt: false,
});

export default mixpanel;
