import React from 'react'

import Header from "../ztn/Header"
import Menu from "../ztn/Menu"
import Main from "../ztn/Main.js"
import Title from './Title'
import Favicon from './Favicon'
import HelpModal from '../ztn/HelpModal'

const MainLayout = (props) => {
  const childrenWithProps = React.Children.map(props.children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });
  const nonMenuRoutes = ['Projects', 'OrganizationPage', 'BillingPage']
  const hideMenuBool = childrenWithProps[0]?.type?.displayName && nonMenuRoutes.includes(childrenWithProps[0]?.type?.displayName)

  return <>
    <Favicon />
    <Title {...props} />
    <Header {...props} />

    <div className="flex flex-col sm:flex-row relative pt-36 sm:pt-20">
      {!hideMenuBool && <Menu {...props} />}

      <HelpModal />
      <Main>
        {props.selectedOrg ? childrenWithProps : null}
      </Main>

    </div>
  </>
}

export default MainLayout;
