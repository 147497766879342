import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { S3Client } from '@aws-sdk/client-s3';
import Auth from './Auth';
import toSafariCompatibleDateString from 'components/lib/toSafariCompatibleDateString';
import { getSessionStorage, setSessionStorage } from '../utils/persistentStorage';

const AwsContext = createContext();
let stsTimeout;

async function fetchSTS(headers) {
  const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/get_s3_credentials`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) throw new Error('Failed to fetch STS credentials');
  const jsonRes = await response.json();

  return jsonRes;
}

export function useAws() {
  return useContext(AwsContext);
}

const now = () => new Date()

const timeUntil = (timeString) => {
  const date = new Date(toSafariCompatibleDateString(timeString));
  return (date?.getTime?.() || 0) - now().getTime();
}

const second = 1000;
const minute = 60 * second;

export const AwsProvider = (props) => {
  const { getAuthHeaders } = useContext(Auth);
  const [sts, setSTS] = useState(null);
  const [s3Client, setS3Client] = useState(null);
  const [key, setKey] = useState('');

  async function renewSTS({ reuse_existing = false } = {}) {
    let stsCreds = reuse_existing
      ? getSessionStorage('sts', null)
      : null;

    const isExpired = timeUntil(stsCreds?.expiration) < (1 * minute)

    if (!stsCreds || isExpired) {
      stsCreds = await fetchSTS(getAuthHeaders().headers);
      setSessionStorage('sts', stsCreds);
    }

    setSTS(stsCreds);

    const timeToExpiry = timeUntil(stsCreds?.expiration)
    const timeout = (timeToExpiry > 0) ? timeToExpiry : (1 * minute);

    if (stsTimeout) clearTimeout(stsTimeout);
    stsTimeout = setTimeout(() => {
      renewSTS({ reuse_existing: false })
    }, timeout);

  };

  useEffect(() => {
    const renew = async () => await renewSTS({ reuse_existing: true });
    renew();
  }, []);

  useEffect(() => {
    if (!sts) return;
    const creds = {
      accessKeyId: sts.sessionId,
      secretAccessKey: sts.sessionKey,
      sessionToken: sts.sessionToken
    }
    if (creds?.['secretAccessKey'] == 'LOCAL_ENV') {
      const tempClient = null
      setS3Client(tempClient)
    } else {
      try {
        const client = new S3Client({
          region: 'us-east-2',
          credentials: creds,
        })
        setS3Client(client);
      } catch (error) {
        setS3Client(null);
        renewSTS({ reuse_existing: false });
      }
    }
  }, [sts]);


  function setRootS3Key(key) {
    setKey(key);
  }

  return (
    <AwsContext.Provider value={{ sts, s3Client, renewSTS, rootS3Key: key, setRootS3Key }}>
      {props.children}
    </AwsContext.Provider>
  );
}

export default useAws;
