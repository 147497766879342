import React, { useState } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import styles from './ForgotPassword.module.css';
import BaseCard from '../ui/BaseCard';
import BaseButton from '../ui/BaseButton';
import Alert from '../ui/Alert';

const EmailSent = (props) => {
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const router = useRouter();
  let timeoutID;

  function resendEmail() {
    axios.post(`${process.env.NEXT_PUBLIC_API_URL}/reset`, {username: props.email})
      .then(() => {
        setAlertMessage('Email resent successfully!');
        setIsError(false);
      })
      .catch(() => {
        setAlertMessage('An error occured while resending. Please try again later.');
        setIsError(true);
      })
      .finally(() => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => setAlertMessage(''), 10000);
      });
  }

  return (
    <BaseCard title="Email Sent!" className={styles.card}>
        {alertMessage &&
          <div className={styles.hud}>
            {isError ?
              <Alert showButton={false} showIcon={false} variant='error'>{alertMessage}</Alert>
            :
              <Alert showButton={false} showIcon={false} variant='success'>{alertMessage}</Alert>
            }
          </div>
        }
        <div className={styles.instruction}>
          A reset password has been sent to you. Please note, the link is valid for 24 hours.
        </div>
        <div className={styles.resendEmail}>
          Received nothing? <span onClick={resendEmail} className="link">Resend Email</span>
        </div>
        <BaseButton width="100" onClick={() => router.replace('/login')}>Login</BaseButton>
    </BaseCard>
  );
};

export default EmailSent;
