import Meter from '../ui/Meter'
import { useAws } from './contexts/Aws';
import { QueryObserver, useQuery } from 'react-query';
import { queryClient } from './contexts/AsyncQuery';
import { getS3PrefixSize } from 'components/ztn/utils/awsUtils';
import prettyBytes from 'pretty-bytes';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

/**
 * Fetch given org's storage usage (in bytes)
 *
 * @param orgName name of the organization to query
 *
 * @returns the react-query state (.data, .error, .isLoading, etc.)
 *
 * @example
 *    import { useOrgStorage } from './StorageMeter'
 *
 *    function component(props) {
 *      const orgStorage = useOrgStorage(props.selectedOrg?.name);
 *      return orgStorage.isLoading
 *        ? <span>Loading...</span>
 *        : <span>Storage used in bytes: {orgStorage.data}</span>
 *    }
 *
 */
export function useOrgStorage(orgName) {
  const { s3Client, renewSTS } = useAws();

  const query = useQuery({
    queryKey: ['storageQuery', orgName],
    queryFn: () => getS3PrefixSize({
      bucket: process.env.NEXT_PUBLIC_AWS_S3_BUCKET,
      prefix: `${orgName}/`,
      client: s3Client,
    }),
    enabled: !!s3Client && !!orgName,
    retry: 2,
    staleTime: 10 * 60 * 1000,
    refetchInterval: 20 * 60 * 1000, // 20 minutes
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    onError: async (error) => await renewSTS({ reuse_existing: false }),
  });

  return query;
}


/**
 * Returns the cached react query object. Useful outside components.
 * Requires useOrgStorage(orgName) be mounted at least once anywhere in the app.
 * Otherwise returns `undefined`.
 *
 * @param orgName organization to query
 *
 * @example
 *    import { getOrgStorage } from './StorageMeter'
 *
 *    function someRegularJS(orgName) {
 *      const orgStorage = getOrgStorage(orgName);
 *      if (!orgStorage) { return }
 *      orgStorage.isFetching
 *        ? console.log('Fetching...')
 *        : console.log(`Storage used in bytes: ${orgStorage.data}`)
 *    }
 *
 * @example
 *    import { getOrgStorage } from './StorageMeter'
 *
 *    function someUploader(orgName) {
 *      //
 *      // ... upload succeeds
 *      //
 *      const orgStorage = getOrgStorage(orgName);
 *      orgStorage?.refetch(); // await here if sync is desired
 *    }
 *
 */
export function getOrgStorage(orgName) {
  const queryKey = ['storageQuery', orgName]

  if (!queryClient.getQueryState(queryKey)) {
    console.error("getOrgStorage failed: useOrgStorage query was never mounted")
    return undefined
  }

  const observer = new QueryObserver(queryClient, { queryKey })
  return observer.getCurrentResult()
}


/**
 * Returns `value` rounded to `d` decimal places.
 */
const toDecimalPlaces = (value, d = 2) =>
  ~~(value * (10 ** d)) / (10 ** d);

/**
 * Displays the given org's storage usage
 *
 * @param org organization to query
 * @param tooltip tippy | alt | none
 * @param vertical boolean
 */
export default function StorageMeter(props) {
  const orgName = props.org?.name;
  const orgPlan = props.org?.plan_status;
  const orgStorage = useOrgStorage(orgName);

  const hasValue = !(orgStorage.data == null);

  if (!hasValue && !orgStorage.isFetching) {
    return null;
  }

  const GB = 10 ** 9;
  const freeMax = 100 * GB;
  const premiumMax = 250 * GB;

  const size = orgStorage.data || 0;
  const sizeMax = (orgPlan == 'free') ? freeMax : premiumMax;
  const percent = 100 * size / sizeMax;

  const prettySize = prettyBytes(size)
  const prettySizeMax = prettyBytes(sizeMax);
  const prettyPercent = `${toDecimalPlaces(percent, 1)}%`
  const prettyTooltip = `Storage used: ${prettySize} of ${prettySizeMax} (${prettyPercent})`

  const tooltipType = hasValue ? (props.tooltip ?? 'tippy') : 'none';

  const meterComponent = (
    <Meter
      value={percent}
      color={percent >= 90 ? "error" : percent >= 70 ? "warning" : "secondary"}
      vertical={false}
      title={hasValue ? "Storage" : '\u00A0'}
      label={hasValue ? prettyPercent : '\u00A0'}
      alt={tooltipType == 'alt' ? prettyTooltip : ""}
      loading={orgStorage.isLoading}
      {...props}
    />
  )

  if (tooltipType == 'tippy') {
    return (
      <Tippy
        theme={'light'}
        delay={400}
        placement='right'
        arrow={true}
        content={
          <span>
            <div className="p-0.5 text-gray-800">
              {hasValue ? prettyTooltip : ""}
            </div>
          </span>
        }
      >
        <span>
          {meterComponent}
        </span>
      </Tippy>
    )
  }

  return meterComponent;
}
