import { capitalize } from "lodash";
import Head from "next/head";
import Router from "next/router";

const Title = (props) => {
  const org = props.selectedOrg?.name
  const proj = props.selectedProject?.name
  const page = capitalize(Router.asPath.split('/')[3])

  const title = [page, proj, org, "Zetane Protector"]
    .filter(s => s)
    .join(' \u00B7 ')

  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}

export default Title;
